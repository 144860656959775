const lang = {
    "EnterWidth": "Connectez-vous avec:",
    "Registration": "S'inscrire",
    "enter": "Entrer",
    "EntrWidthEmailOrPhone": "Connectez-vous par e-mail ou par téléphone",
    "login": "Autorisation",
    "NoAkkGoReg": "Vous n'avez pas de compte ? S'inscrire",
    "Password": "Mot de passe",
    "Name": "Nom",
    "LastName": "Nom de famille",
    "Phone": "Téléphone",
    "Email": "Poster",
    "RepeatPassword": "Répéter le mot de passe",
    "Сancel": "Annuler",
    "Save": "Sauver",
    "Language": "Langue",
    "ConfirmThisMail": "Votre email a été confirmé",
    "Saved": "Enregistré",
    "Error": "Erreur",
    "FillInAllTheFields": "Remplissez tous les champs",
    "Next": "Plus loin",
    "MinPassLength": "Longueur minimale du mot de passe 4 caractères",
    "ForgotYourPassword": "Mot de passe oublié",
    "RestorePassword": "Restaurer le mot de passe",
    "UserMustBeRegistered": "L'utilisateur doit être enregistré",
    "Confirm": "Confirmer",
    "WrongFormat": "Mauvais format",
    "WrongPassword": "Mot de passe incorrect",
    "WrongLoginOrPassword": "Identifiant ou mot de passe incorrect",
    "PhoneAlreadyRegistered": "Téléphone déjà enregistré",
    "EmailAlreadyRegistered": "Courrier déjà enregistré",
    "FillInTheFieldsCorrectly": "Remplissez correctement les champs",
    "ThisAccountDoesNotExist": "Ce compte n'existe pas",
    "LinkIsNotValid": "Le lien n'est pas valide",
    "AMessageHasBeenSentToTheSpecifiedLogin": "Un message a été envoyé au nom d'utilisateur spécifié",
    "IncorrectCaptcha": "Mauvais captcha",
    "CaptchaIsNotValid": "Le captcha n'est plus valide",
    "EnterCaptcha": "Entrez le capcha",
    "EnterSMS": "Entrez le code du SMS",
    "SMSCodeIsNoLongerValid": "Le code SMS n'est plus valide",
    "SMSCodeIsIncorrect": "Le code SMS est incorrect",
    "MailNotVerifiedLinkTimedOut": "Mail non confirmé, lien expiré",
    "DemoLogin": "Connexion démo",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Voulez-vous vraiment accéder à la <b> démo </b> pour l'essayer ?",
    "FillInAdditionalInformation": "Remplissez les informations supplémentaires",
    "ToCompleteRegistration": "Pour terminer l'inscription",
    "LinkThirdPartyServices": "Lier des services tiers",
    "Complete": "Compléter",
    "AccountAlreadyLinked": "Compte déjà lié",
    "AccountNotRegistered": "Compte non enregistré",
    "NotMyNumber": "Pas mon numéro",
    "Back": "Arrière",
    "numberOfAttemptsToCheckAuth": "Nombre de tentatives de connexion :",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Mot de passe erroné, nombre de tentatives de connexion :",
    "TryAgainAfterMinute": "Veuillez réessayer dans une minute",
    "Help": "Soutien",
    "y": "Oui",
    "n": "Pas",
    "doYouConfirmEmail": "Voulez-vous vraiment changer d'e-mail ?",
    "success": "Avec succès",
    "goToAutorize": "Aller à l'autorisation",
    "UserEmailAlreadyExists": "Utilisateur avec ce courriel existe déjà",
    "UserPhoneAlreadyExists": "L'utilisateur avec ce numéro de téléphone existe déjà",
    "TermOfUse": "Conditions d'utilisation",
    "PrivacyPolicy": "Politique de confidentialité",
    "accountNotRegistered": "compte non enregistré",
    "wantToReg": "Voulez-vous vous inscrire?",
    "weSuccessChangeYouEmail": "Nous avons changé votre adresse e-mail avec succès",
    "youCanCloseThisWindow": "Tu peux fermer cette fenêtre",
    "countryConfirm": "Confirmation du pays",
    "EmailAlreadyTied": "Le courrier est déjà lié à un autre compte",
    "areYourCountry": "est-ce votre pays?",
    "EmergencyEntrance": "Ancienne autorisation",
    "enterCorrectNumber": "Entrez le numéro au format international",
    "thisPhoneRegister": "Ce numéro de téléphone est déjà enregistré dans le système",
    "wantUsePhoneForAuth": "Voulez-vous vous connecter avec ce numéro de téléphone ?",
    "ok": "OK",
    "regAgree": "En enregistrant un compte, j'accepte",
    "wantBind": "Vous souhaitez vous connecter et lier le service",
    "bind": "lier",
    "yoursMailBinded": "Votre e-mail est dans un compte existant",
    "wantUsePhoneAndBind": "déjà enregistré dans le système",
    "notGetDataFromApple": "Échec de la réception des données d'Apple",
    "ErrorAppleLogin": "Erreur d'autorisation via Apple",
    "ErrorGoogleLogin": "Erreur lors de l'autorisation via Google",
    "ErrorTelegramLogin": "Erreur lors de la connexion via Telegram",
    "ErrorFacebookLogin": "Erreur de connexion via Facebook",
    "EMailOrPhone": "Veuillez entrer un numéro de téléphone ou une adresse email valide.",
    "withoutName": "Pas de nom",
    "goToCabinet": "Au bureau",
    "Logout": "Fin de la session",
    "creationDate": "Date de création",
    "preparingCompany1": "Nous créons votre entreprise, accordez-nous une minute...",
    "preparingCompany2": "Nous préparons une interface de gestion des biens et services...",
    "preparingCompany3": "Nous travaillons sur une comptabilité financière pratique...",
    "preparingCompany4": "Nous effectuons les derniers réglages, presque prêts..."
}

export default function() {
    return lang;
}