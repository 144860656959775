<template>
  <smallModal @escape="$emit('close')">
    <template v-if="showStartModal">
      <div ref="startModal" tabindex="0">
        <div class="usr-icon-login-container">
          <div class="user-icon-div">
            <img src="@/assets/userSVG.svg" class="account-icon" alt=""/>
          </div>
          <span class="person-login">AppleID{{ " " + lang["accountNotRegistered"] }}</span>
        </div>
        <br />
        <div>
          <div class="center">
            <span class="person-login">{{ lang["wantToReg"] }}</span>
          </div>
        </div>
        <hr />
        <div class="buttons" v-if="isActiveStartModal">
          <button class="btn btn-secondary" @click="backToLogin">
            {{ lang["Сancel"] }}
          </button>
          <button class="btn btn-success btn-go-reg" @click="wantToReg">
            {{ lang["y"] }}
          </button>
        </div>
      </div>
    </template>
    <template v-if="showContinueModal">
      <div class="continueModalDiv">
        <label for="person_login_input_regiter">{{ lang["Phone"] }}</label>
        <div class="text-field__icon text-field__icon_plus">
          <b-form-input
            autofocus
            type="text"
            v-model="form.phone"
            name="login"
            id="input-invalid"
            :state="phoneState"
          />
        </div>
        <hr />
        <div class="buttons">
          <button class="btn btn-secondary" @click="backToLogin">
            {{ lang["Сancel"] }}
          </button>
          <b-button class="btn btn-success btn-go-reg" @click="validPhoneFormat">
            {{ lang["Registration"] }}
          </b-button>
        </div>
      </div>
    </template>
    <template v-if="showCountryModal">
      <div class="countryModalDiv">
        <p class="center">{{ this.country.englishname + " - " + lang["areYourCountry"] }} </p>
        <div class="buttons">
          <button class="btn btn-secondary" @click="wantToReg">
            {{ lang["Back"] }}
          </button>
          <b-button class="btn btn-success btn-go-reg" @click="sendPhone">
            {{ lang["Confirm"] }}
          </b-button>
        </div>
      </div>
    </template>
    <template v-if="showRegisterPhoneModal">
      <div class="phoneModalDiv">
        <span><b>{{ this.form.phone }}</b> - {{ lang["wantUsePhoneAndBind"] }}.</span>
        <br>
        <span>{{ lang['wantBind'] }} <b>AppleID</b>?</span>
        <hr>
        <div class="buttons">
          <button class="btn btn-secondary" @click="wantToReg">
            {{ lang["Back"] }}
          </button>
          <b-button class="btn btn-success btn-go-reg" @click="openAuthModal">
            {{ lang["login"] }}
          </b-button>
        </div>
      </div>
    </template>
    <template v-if="authModal">
      <div>
        <div class="usr-icon-login-container">
          <div class="user-icon-div">
            <img src="@/assets/userSVG.svg" class="account-icon" alt="">
          </div>
          <span class="person-login">{{ this.form.phone }}</span>
        </div>
        <hr>
        <div>
          <div>
            <span>{{ lang['Password'] }}</span>
            <b-form-input type="password" v-model="form.password" :state="statePass" id="pass" autofocus />
          </div>
        </div>
        <hr>
        <div class="buttons">
          <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
          <button class="btn btn-success" @click="save()">{{ lang['enter'] }}</button>
        </div>
      </div>
    </template>
  </smallModal>
</template>

<script>
  import smallModal from "../modals/smallModal";
  import { keyevent } from '@/main.js';
  import { store } from "@/store.js";
  import { regAPi } from "@/API.js";
  import Cookies from "js-cookie";
  const apiList = new regAPi();
  let apiCookies = Cookies;
  if (process.env.NODE_ENV == 'production') {
    apiCookies = Cookies.withAttributes({domain: ".skyservice.online"})
  }
  export default {
    props: ["serviceAppleToken"],
    components: {
      smallModal,
    },
    data() {
      return {
        show: false,
        showStartModal: false,
        showContinueModal: false,
        showCountryModal: false, 
        showRegisterPhoneModal: false,
        isActiveStartModal: false,
        authModal: false,
        country: {
          englishname: ''
        }, 
        form: {
          phone: "",
          password: "",
        },
      };
    },
    created() {
      this.showStartModal = true
      this.isActiveStartModal = true
      this.$nextTick(() => {
        this.$refs.startModal.focus();
      });
      keyevent.$off('enter')
      keyevent.$on('enter', () => {
        if(this.showStartModal){
          this.wantToReg()
        }
      })
    },
    methods: {
      // пользователь нажал хочу зарегистрироваться либо же если с модалки страны хотим обратно
      wantToReg() {
        this.showStartModal = true
        this.showContinueModal = true;
        this.isActiveStartModal = false;
        this.showCountryModal = false;
        this.showRegisterPhoneModal = false;
        keyevent.$off('enter')
        keyevent.$on('enter', () => {
          if(this.showContinueModal){
            this.validPhoneFormat()
          }
        })
      },
      // выходим с модалок
      backToLogin() {
        this.$emit('close')
      },
      // открываем модалку логина
      openAuthModal(){
        this.showRegisterPhoneModal = false
        this.authModal = true
        keyevent.$off('enter')
        keyevent.$on('enter', () => this.save())
      },
      // кидаем запрос на сервер, проверить правильность номера
      validPhoneFormat() {
        if (this.phoneState) {
          const obj = { 
            phone: this.form.phone,
            ref_id:  localStorage.getItem('ref_id') != null ? localStorage.getItem('ref_id') : ''
          }
          apiList.phoneFormatValidation(obj).then((resp) => {
            console.log(resp);
            if(resp.data.status === "done") {
              if(resp.data.data.viewModal){
                this.country = resp.data.data.country;
                this.showContinueModal = false
                this.showStartModal = false
                this.showCountryModal = true
                keyevent.$off('enter')
                keyevent.$on('enter', () => {
                  if(this.showCountryModal){
                    this.sendPhone()
                  }
                })
              } else {
                this.sendPhone();
              }
            } else {
              window.say.messageError(this.lang['WrongFormat'])
            }
          });
        } else {
          window.say.messageError(this.lang['WrongFormat'])
        }
      },
      // зберігаємо зміни
      save() {
        try {
          if (this.statePass) {
            apiList.bindSocialApple({ user_phone: this.form.phone, apple_token: this.serviceAppleToken, user_password: this.form.password }).then(resp => {
              if (resp.data.status == 'done') {
                apiCookies.set("token", resp.data.data.token, {expires: 180});
                document.location.href = "https://cabinet.skyservice.online";
              } else {
                if (resp.data.error == "Wrong password") {
                  window.say.messageError(this.lang["WrongPassword"]);
                } else if (resp.data.error == "Google token is incorrect") {
                  window.say.messageError(this.lang["Error"]);
                } else {
                  window.say.messageError(this.lang["Error"]);
                }
              }
            })
          } else {
            window.say.messageError(this.lang['MinPassLength']);
          }
        } catch(err) {
          // console.log(err)
        }
      },
      // кидаем основной запрос на сервер
      sendPhone() {
        try {
          const obj = {
            ref_id: localStorage.getItem("ref_id") != null ? localStorage.getItem("ref_id") : "",
            apple_token: this.serviceAppleToken,
            phone: this.form.phone,
            lang: store.lang
          };
          apiList.registrationApple(obj).then((resp) => {
            if (resp.data.status == "done") {
              apiCookies.set("token", resp.data.data.token, {expires: 180,
                domain: ".skyservice.online",
              });
              document.location.href = "https://cabinet.skyservice.online";
              } else if(resp.data.error == "Phone is already registered"){
                this.showStartModal = false;
                this.showContinueModal = false;
                this.isActiveStartModal = false;
                this.showRegisterPhoneModal = true;
                this.showCountryModal = false;
                keyevent.$off('enter')
                keyevent.$on('enter', () => this.openAuthModal())
                window.say.messageError(this.lang["UserPhoneAlreadyExists"]);
              } else if(resp.data.error == "Email is already registered"){
                this.showStartModal = true
                this.showContinueModal = true;
                this.isActiveStartModal = false;
                this.showCountryModal = false;
                this.showRegisterPhoneModal = false;
              window.say.messageError(this.lang["EmailAlreadyTied"]);
            } else {
                this.form.phone = "";
                window.say.messageError(this.lang["Error"]);
            }
          });
        } catch (err) {
          // console.log(err)
        }
      },
    },
    computed: {
      phoneState() {
        if (this.form.phone && !isNaN(this.form.phone)) {
          return true;
        } else {
          return false;
        }
      },
      lang() {
        return window.lang;
      },
      w() {
        return store.window.width;
      },
      statePass() {
        return this.form.password.length >= 4 ? true : false;
      },
    },
  };
</script>

<style scoped>
.center{
  text-align: center;
}
.phoneModalDiv, 
.countryModalDiv, 
.continueModalDiv{
  padding: 9 15px; 
  position: relative; 
  width: 100%;
}
.account-icon{
  height: 3rem;
}
.buttons{
  display: flex; 
  justify-content: space-between;
}
.btn{
  width: 49%;
}
.text-field__icon::before {
  content: "";
  position: absolute;
  display: flex;
  align-items: center;
  top: 36.5%;
  bottom: 0;
  left: 0.625rem;
  transform: translateY(-50%);
}

.text-field__icon input {
  padding-left: 1.2rem;
}

.text-field__icon_plus::before {
  content: "+";
}
.usr-icon-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.user-icon-div {
  /* border: 1px solid gray; */
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 999px;
  margin-bottom: 1rem;
}
.person-login {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.form-reg_input {
  margin-bottom: 0 !important;
  padding-left: 1.5rem;
}
@media screen and (max-width: 700px) {
  .btn-go-reg {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>