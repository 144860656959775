const lang = {
    "EnterWidth": "Einloggen mit:",
    "Registration": "Registrieren",
    "enter": "Betreten",
    "EntrWidthEmailOrPhone": "Login per E-Mail oder Telefon",
    "login": "Genehmigung",
    "NoAkkGoReg": "Sie haben kein Konto? Registrieren",
    "Password": "Passwort",
    "Name": "Name",
    "LastName": "Familien-oder Nachname",
    "Phone": "Telefon",
    "Email": "Post",
    "RepeatPassword": "Passwort wiederholen",
    "Сancel": "Absagen",
    "Save": "Speichern",
    "Language": "Sprache",
    "ConfirmThisMail": "Deine Email wurde bestätigt",
    "Saved": "Gerettet",
    "Error": "Fehler",
    "FillInAllTheFields": "Füllen Sie alle Felder aus",
    "Next": "Weiter",
    "MinPassLength": "Minimale Passwortlänge 4 Zeichen",
    "ForgotYourPassword": "Passwort vergessen",
    "RestorePassword": "Passwort wiederherstellen",
    "UserMustBeRegistered": "Benutzer muss registriert sein",
    "Confirm": "Bestätigen Sie",
    "WrongFormat": "Falsches Format",
    "WrongPassword": "Falsches Passwort",
    "WrongLoginOrPassword": "falsches Login oder Passwort",
    "PhoneAlreadyRegistered": "Telefon bereits registriert",
    "EmailAlreadyRegistered": "Post bereits registriert",
    "FillInTheFieldsCorrectly": "Füllen Sie die Felder richtig aus",
    "ThisAccountDoesNotExist": "Dieser Account existiert nicht",
    "LinkIsNotValid": "Link ist nicht gültig",
    "AMessageHasBeenSentToTheSpecifiedLogin": "Eine Nachricht wurde an den angegebenen Benutzernamen gesendet",
    "IncorrectCaptcha": "Falsches Captcha",
    "CaptchaIsNotValid": "Captcha ist nicht mehr gültig",
    "EnterCaptcha": "Captcha eingeben",
    "EnterSMS": "Code aus SMS eingeben",
    "SMSCodeIsNoLongerValid": "SMS-Code ist nicht mehr gültig",
    "SMSCodeIsIncorrect": "SMS-Code ist falsch",
    "MailNotVerifiedLinkTimedOut": "Mail nicht bestätigt, Link abgelaufen",
    "DemoLogin": "Demo-Login",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Möchten Sie wirklich an der <b> Demo </b> , um es auszuprobieren?",
    "FillInAdditionalInformation": "Füllen Sie zusätzliche Informationen aus",
    "ToCompleteRegistration": "Um die Registrierung abzuschließen",
    "LinkThirdPartyServices": "Dienste von Drittanbietern verknüpfen",
    "Complete": "Fertigstellen",
    "AccountAlreadyLinked": "Konto bereits verknüpft",
    "AccountNotRegistered": "Konto nicht registriert",
    "NotMyNumber": "Nicht meine Nummer",
    "Back": "zurück",
    "numberOfAttemptsToCheckAuth": "Anzahl Anmeldeversuche:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Falsches Passwort, Anzahl Anmeldeversuche:",
    "TryAgainAfterMinute": "Bitte versuchen Sie es in einer Minute erneut",
    "Help": "Unterstützung",
    "y": "Ja",
    "n": "Nein",
    "doYouConfirmEmail": "Möchten Sie Ihre E-Mail-Adresse wirklich ändern?",
    "success": "Erfolgreich",
    "goToAutorize": "Gehen Sie zur Autorisierung",
    "UserEmailAlreadyExists": "Ein Benutzer existiert bereits mit dieser E-Mail-Adresse",
    "UserPhoneAlreadyExists": "Benutzer mit dieser Telefonnummer existiert bereits",
    "TermOfUse": "Nutzungsbedingungen",
    "PrivacyPolicy": "Datenschutz-Bestimmungen",
    "accountNotRegistered": "Konto nicht registriert",
    "wantToReg": "Möchten Sie sich anmelden?",
    "weSuccessChangeYouEmail": "Wir haben Ihre E-Mail-Adresse erfolgreich geändert",
    "youCanCloseThisWindow": "Sie können dieses Fenster schließen",
    "countryConfirm": "Länderbestätigung",
    "EmailAlreadyTied": "Mail ist bereits mit einem anderen Konto verknüpft",
    "areYourCountry": "Ist das dein Land?",
    "EmergencyEntrance": "Alte Zulassung",
    "enterCorrectNumber": "Nummer im internationalen Format eingeben",
    "thisPhoneRegister": "Diese Telefonnummer ist bereits im System registriert",
    "wantUsePhoneForAuth": "Möchten Sie sich mit dieser Telefonnummer anmelden?",
    "ok": "ok",
    "regAgree": "Durch die Registrierung eines Kontos akzeptiere ich",
    "wantBind": "Sie möchten sich anmelden und den Dienst verknüpfen",
    "bind": "binden",
    "yoursMailBinded": "Ihre E-Mail-Adresse befindet sich in einem bestehenden Konto",
    "wantUsePhoneAndBind": "bereits im System registriert",
    "notGetDataFromApple": "Es konnten keine Daten von Apple empfangen werden",
    "ErrorAppleLogin": "Fehler bei der Autorisierung über Apple",
    "ErrorGoogleLogin": "Fehler bei der Autorisierung über Google",
    "ErrorTelegramLogin": "Fehler beim Einloggen über Telegram",
    "ErrorFacebookLogin": "Fehler beim Anmelden über Facebook",
    "EMailOrPhone": "Bitte geben Sie eine gültige Telefonnummer oder E-Mail-Adresse ein.",
    "withoutName": "Kein Name",
    "goToCabinet": "Zum Büro",
    "Logout": "Sitzung beenden",
    "creationDate": "Erstellungsdatum",
    "preparingCompany1": "Wir gründen Ihr Unternehmen, geben Sie uns bitte eine Minute …",
    "preparingCompany2": "Wir bereiten eine Schnittstelle zur Verwaltung von Waren und Dienstleistungen vor …",
    "preparingCompany3": "Wir arbeiten an einer komfortablen Finanzbuchhaltung…",
    "preparingCompany4": "Wir nehmen die letzten Einstellungen vor, fast fertig …"
}

export default function() {
    return lang;
}