const lang = {
    "EnterWidth": "Entra con:",
    "Registration": "Registrati",
    "enter": "Entrare",
    "EntrWidthEmailOrPhone": "Accedi via e-mail o telefono",
    "login": "Autorizzazione",
    "NoAkkGoReg": "Non hai un account? Registrati",
    "Password": "Parola d'ordine",
    "Name": "Nome",
    "LastName": "Cognome",
    "Phone": "Telefono",
    "Email": "Posta",
    "RepeatPassword": "ripeti la password",
    "Сancel": "Annulla",
    "Save": "Salva",
    "Language": "Lingua",
    "ConfirmThisMail": "la tua email è stata confermata",
    "Saved": "Salvato",
    "Error": "Errore",
    "FillInAllTheFields": "Compila tutti i campi",
    "Next": "Ulteriore",
    "MinPassLength": "Lunghezza minima password 4 caratteri",
    "ForgotYourPassword": "Hai dimenticato la password",
    "RestorePassword": "Ripristina password",
    "UserMustBeRegistered": "L'utente deve essere registrato",
    "Confirm": "Confermare",
    "WrongFormat": "Formato errato",
    "WrongPassword": "password errata",
    "WrongLoginOrPassword": "nome utente o password errati",
    "PhoneAlreadyRegistered": "Telefono già registrato",
    "EmailAlreadyRegistered": "Posta già registrata",
    "FillInTheFieldsCorrectly": "Compila correttamente i campi",
    "ThisAccountDoesNotExist": "questo account non esiste",
    "LinkIsNotValid": "Il collegamento non è valido",
    "AMessageHasBeenSentToTheSpecifiedLogin": "È stato inviato un messaggio al nome utente specificato",
    "IncorrectCaptcha": "Captcha errato",
    "CaptchaIsNotValid": "Captcha non è più valido",
    "EnterCaptcha": "Inserisci captcha",
    "EnterSMS": "Inserisci il codice da SMS",
    "SMSCodeIsNoLongerValid": "Il codice SMS non è più valido",
    "SMSCodeIsIncorrect": "Il codice SMS non è corretto",
    "MailNotVerifiedLinkTimedOut": "Mail non confermata, link scaduto",
    "DemoLogin": "Accesso demo",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Sei sicuro di voler entrare nella <b> demo </b> per provarla?",
    "FillInAdditionalInformation": "Inserisci informazioni aggiuntive",
    "ToCompleteRegistration": "Per completare la registrazione",
    "LinkThirdPartyServices": "Collega servizi di terze parti",
    "Complete": "Completare",
    "AccountAlreadyLinked": "Account già collegato",
    "AccountNotRegistered": "Conto non registrato",
    "NotMyNumber": "Non il mio numero",
    "Back": "Di ritorno",
    "numberOfAttemptsToCheckAuth": "Numero di tentativi di accesso:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Password errata, numero di tentativi di accesso:",
    "TryAgainAfterMinute": "Riprova tra un minuto",
    "Help": "Supporto",
    "y": "sì",
    "n": "Non",
    "doYouConfirmEmail": "Sei sicuro di voler cambiare la tua email?",
    "success": "Con successo",
    "goToAutorize": "Vai all'autorizzazione",
    "UserEmailAlreadyExists": "L'utente con questa email esiste già",
    "UserPhoneAlreadyExists": "L'utente con questo numero di telefono esiste già",
    "TermOfUse": "Condizioni d'uso",
    "PrivacyPolicy": "politica sulla riservatezza",
    "accountNotRegistered": "conto non registrato",
    "wantToReg": "Vuoi registrarti?",
    "weSuccessChangeYouEmail": "Abbiamo cambiato con successo il tuo indirizzo email",
    "youCanCloseThisWindow": "Puoi chiudere questa finestra",
    "countryConfirm": "Conferma Paese",
    "EmailAlreadyTied": "La posta è già collegata a un altro account",
    "areYourCountry": "questo è il tuo paese?",
    "EmergencyEntrance": "Vecchia Autorizzazione",
    "enterCorrectNumber": "Inserisci il numero in formato internazionale",
    "thisPhoneRegister": "Questo numero di telefono è già registrato nel sistema",
    "wantUsePhoneForAuth": "Vuoi accedere utilizzando questo numero di telefono?",
    "ok": "ok",
    "regAgree": "Registrando un account, accetto",
    "wantBind": "Vuoi accedere e collegare il servizio",
    "bind": "legamento",
    "yoursMailBinded": "La tua email è in un account esistente",
    "wantUsePhoneAndBind": "già registrato nel sistema",
    "notGetDataFromApple": "Impossibile ricevere dati da Apple",
    "ErrorAppleLogin": "Errore durante l'autorizzazione tramite Apple",
    "ErrorGoogleLogin": "Errore durante l'autorizzazione tramite Google",
    "ErrorTelegramLogin": "Errore durante l'accesso tramite Telegram",
    "ErrorFacebookLogin": "Errore durante l'accesso tramite Facebook",
    "EMailOrPhone": "Inserisci un numero di telefono o un indirizzo email valido.",
    "withoutName": "Nessun nome",
    "goToCabinet": "In ufficio",
    "Logout": "Termina sessione",
    "creationDate": "Data di creazione",
    "preparingCompany1": "Stiamo creando la tua azienda, per favore concedici un minuto...",
    "preparingCompany2": "Stiamo preparando un'interfaccia per la gestione di beni e servizi...",
    "preparingCompany3": "Stiamo lavorando per rendere la contabilità finanziaria più pratica...",
    "preparingCompany4": "Stiamo ultimando le impostazioni, siamo quasi pronti..."
}

export default function() {
    return lang;
}