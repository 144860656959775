const lang = {
    "EnterWidth": "Pieteikties ar:",
    "Registration": "Reģistrēties",
    "enter": "Lai ienāktu",
    "EntrWidthEmailOrPhone": "Pieteikties pa e-pastu vai tālruni",
    "login": "Autorizācija",
    "NoAkkGoReg": "Vai jums nav konta? Reģistrēties",
    "Password": "Parole",
    "Name": "Vārds",
    "LastName": "Uzvārds",
    "Phone": "Tālrunis",
    "Email": "Pasts",
    "RepeatPassword": "atkārtojiet paroli",
    "Сancel": "Atcelt",
    "Save": "Saglabāt",
    "Language": "Valoda",
    "ConfirmThisMail": "Jūsu e-pasts ir apstiprināts",
    "Saved": "Saglabāts",
    "Error": "Kļūda",
    "FillInAllTheFields": "Aizpildiet visus laukus",
    "Next": "Tālāk",
    "MinPassLength": "Minimālais paroles garums 4 rakstzīmes",
    "ForgotYourPassword": "Aizmirsi savu paroli",
    "RestorePassword": "Atjaunot paroli",
    "UserMustBeRegistered": "Lietotājam jābūt reģistrētam",
    "Confirm": "Apstiprināt",
    "WrongFormat": "Nepareizs formāts",
    "WrongPassword": "nepareiza parole",
    "WrongLoginOrPassword": "nepareizs pieteikšanās vārds vai parole",
    "PhoneAlreadyRegistered": "Tālrunis jau reģistrēts",
    "EmailAlreadyRegistered": "Pasts jau reģistrēts",
    "FillInTheFieldsCorrectly": "Pareizi aizpildiet laukus",
    "ThisAccountDoesNotExist": "Šis konts neeksistē",
    "LinkIsNotValid": "Saite nav derīga",
    "AMessageHasBeenSentToTheSpecifiedLogin": "Uz norādīto lietotājvārdu ir nosūtīta ziņa",
    "IncorrectCaptcha": "Nepareiza captcha",
    "CaptchaIsNotValid": "Captcha vairs nav derīga",
    "EnterCaptcha": "Ievadiet captcha",
    "EnterSMS": "Ievadiet kodu no SMS",
    "SMSCodeIsNoLongerValid": "SMS kods vairs nav derīgs",
    "SMSCodeIsIncorrect": "SMS kods nav pareizs",
    "MailNotVerifiedLinkTimedOut": "Pasts nav apstiprināts, saite beidzās",
    "DemoLogin": "Demo pieteikšanās",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Vai tiešām vēlaties ievadīt <b> demonstrāciju </b> , lai to izmēģinātu?",
    "FillInAdditionalInformation": "Aizpildiet papildu informāciju",
    "ToCompleteRegistration": "Lai pabeigtu reģistrāciju",
    "LinkThirdPartyServices": "Saistiet trešo pušu pakalpojumus",
    "Complete": "Lai pabeigtu",
    "AccountAlreadyLinked": "Konts jau ir saistīts",
    "AccountNotRegistered": "Konts nav reģistrēts",
    "NotMyNumber": "Nav mans numurs",
    "Back": "Atpakaļ",
    "numberOfAttemptsToCheckAuth": "Pieteikšanās mēģinājumu skaits:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Nepareiza parole, pieteikšanās mēģinājumu skaits:",
    "TryAgainAfterMinute": "Lūdzu, mēģiniet vēlreiz pēc minūtes",
    "Help": "Atbalsts",
    "y": "Jā",
    "n": "Nav",
    "doYouConfirmEmail": "Vai tiešām vēlaties mainīt savu e-pastu?",
    "success": "Veiksmīgi",
    "goToAutorize": "Dodieties uz autorizāciju",
    "UserEmailAlreadyExists": "Lietotājs ar šo e-pasta adresi jau pastāv",
    "UserPhoneAlreadyExists": "Lietotājs ar šo tālruņa numuru jau pastāv",
    "TermOfUse": "Lietošanas noteikumi",
    "PrivacyPolicy": "Privātuma politika",
    "accountNotRegistered": "konts nav reģistrēts",
    "wantToReg": "Vai vēlaties reģistrēties?",
    "weSuccessChangeYouEmail": "Mēs esam veiksmīgi nomainījuši jūsu e-pasta adresi",
    "youCanCloseThisWindow": "Jūs varat aizvērt šo logu",
    "countryConfirm": "Valsts apstiprinājums",
    "EmailAlreadyTied": "Pasts jau ir saistīts ar citu kontu",
    "areYourCountry": "vai šī ir tava valsts?",
    "EmergencyEntrance": "Vecā autorizācija",
    "enterCorrectNumber": "Ievadiet numuru starptautiskā formātā",
    "thisPhoneRegister": "Šis tālruņa numurs jau ir reģistrēts sistēmā",
    "wantUsePhoneForAuth": "Vai vēlaties pieteikties, izmantojot šo tālruņa numuru?",
    "ok": "labi",
    "regAgree": "Reģistrējot kontu, es piekrītu",
    "wantBind": "Vēlaties pierakstīties un saistīt pakalpojumu",
    "bind": "saistīt",
    "yoursMailBinded": "Jūsu e-pasts ir esošā kontā",
    "wantUsePhoneAndBind": "jau reģistrēts sistēmā",
    "notGetDataFromApple": "Neizdevās saņemt datus no Apple",
    "ErrorAppleLogin": "Autorizējot, izmantojot Apple, radās kļūda",
    "ErrorGoogleLogin": "Autorizējot, izmantojot Google, radās kļūda",
    "ErrorTelegramLogin": "Kļūda, piesakoties, izmantojot Telegram",
    "ErrorFacebookLogin": "Kļūda, piesakoties caur Facebook",
    "EMailOrPhone": "Lūdzu, ievadiet derīgu tālruņa numuru vai e-pasta adresi.",
    "withoutName": "Nav vārda",
    "goToCabinet": "Uz biroju",
    "Logout": "Beigt sesiju",
    "creationDate": "Izveidošanas datums",
    "preparingCompany1": "Mēs veidojam jūsu uzņēmumu, lūdzu, veltiet mums brīdi...",
    "preparingCompany2": "Mēs gatavojam saskarni preču un pakalpojumu pārvaldībai...",
    "preparingCompany3": "Strādājam pie ērtas finanšu uzskaites...",
    "preparingCompany4": "Mēs veicam galīgos iestatījumus, gandrīz gatavs..."
}

export default function() {
    return lang;
}