const lang = {
    "EnterWidth": "Увійти через:",
    "Registration": "Зареєструватись",
    "enter": "Увійти",
    "EntrWidthEmailOrPhone": "Увійти через пошту чи телефон",
    "login": "Авторизація",
    "NoAkkGoReg": "Немає облікового запису? Зареєструйтесь",
    "Password": "Пароль",
    "Name": "Ім'я",
    "LastName": "Прізвище",
    "Phone": "Телефон",
    "Email": "Пошта",
    "RepeatPassword": "Повторити пароль",
    "Сancel": "Скасувати",
    "Save": "Зберегти",
    "Language": "Мова",
    "ConfirmThisMail": "Ваш e-mail підтверджений",
    "Saved": "Збережено",
    "Error": "Помилка",
    "FillInAllTheFields": "Заповніть всі поля",
    "Next": "Далі",
    "MinPassLength": "Мінімальна довжина пароля 4 символів",
    "ForgotYourPassword": "Забули пароль",
    "RestorePassword": "Відновити пароль",
    "UserMustBeRegistered": "Користувач має бути зареєстрований",
    "Confirm": "Підтвердити",
    "WrongFormat": "Невірний формат",
    "WrongPassword": "Невірний пароль",
    "WrongLoginOrPassword": "Невірний логін або пароль",
    "PhoneAlreadyRegistered": "Телефон уже зареєстровано",
    "EmailAlreadyRegistered": "Пошта вже зареєстрована",
    "FillInTheFieldsCorrectly": "Коректно заповніть поля",
    "ThisAccountDoesNotExist": "Цей обліковий запис не існує",
    "LinkIsNotValid": "Посилання не дійсне",
    "AMessageHasBeenSentToTheSpecifiedLogin": "На вказаний логін надіслано повідомлення",
    "IncorrectCaptcha": "Неправильна капча",
    "CaptchaIsNotValid": "Капча більше недійсна",
    "EnterCaptcha": "Введіть капчу",
    "EnterSMS": "Введіть код із СМС",
    "SMSCodeIsNoLongerValid": "СМС код не дійсний",
    "SMSCodeIsIncorrect": "СМС код невірний",
    "MailNotVerifiedLinkTimedOut": "Пошта не підтверджена, минув час посилання",
    "DemoLogin": "Демо вхід",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Ви дійсно хочете увійти до демонстраційної версії для ознайомлення?",
    "FillInAdditionalInformation": "Заповнити додаткову інформацію",
    "ToCompleteRegistration": "Для завершення реєстрації",
    "LinkThirdPartyServices": "Прив'язати сторонні послуги",
    "Complete": "Завершити",
    "AccountAlreadyLinked": "Акаунт вже прив'язаний",
    "AccountNotRegistered": "Обліковий запис не зареєстровано",
    "NotMyNumber": "Не мій номер",
    "Back": "Назад",
    "numberOfAttemptsToCheckAuth": "Кількість спроб для входу:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Невірний пароль, кількість спроб для входу:",
    "TryAgainAfterMinute": "Повторіть спробу за хвилину",
    "Help": "Техпідтримка",
    "y": "Так",
    "n": "Ні",
    "doYouConfirmEmail": "Ви дійсно хочете змінити вашу електронну пошту?",
    "success": "Успішно",
    "goToAutorize": "Перейти до авторизації",
    "UserEmailAlreadyExists": "Користувач із такою поштою вже існує",
    "UserPhoneAlreadyExists": "Користувач із таким телефоном вже існує",
    "TermOfUse": "Умови використання",
    "PrivacyPolicy": "Політика конфіденційності",
    "accountNotRegistered": "акаунт не зареєстрований",
    "wantToReg": "Бажаєте зареєструватися?",
    "weSuccessChangeYouEmail": "Ми успішно змінили вашу адресу електронної пошти",
    "youCanCloseThisWindow": "Ви можете закрити це вікно",
    "countryConfirm": "Підтвердження країни",
    "EmailAlreadyTied": "Пошта вже привʼязана до іншого облікового запису",
    "areYourCountry": "це ваша країна?",
    "EmergencyEntrance": "Стара авторизація!!!",
    "enterCorrectNumber": "Введіть номер в міжнародному форматі",
    "thisPhoneRegister": "Такий номер телефону вже зареєстровано в системі",
    "wantUsePhoneForAuth": "Хочете авторизуватися за допомогою цього номера телефону?",
    "ok": "Ок",
    "regAgree": "Реєструючи обліковий запис, я приймаю",
    "wantBind": "Бажаєте увійти до облікового запису та прив'язати сервіс",
    "bind": "Прив'язати",
    "yoursMailBinded": "Вашу пошту вказано в наявному обліковому записі",
    "wantUsePhoneAndBind": "вже зареєстровано у системі",
    "notGetDataFromApple": "Не вдалося отримати дані від Apple",
    "ErrorAppleLogin": "Помилка при авторизації через Apple",
    "ErrorGoogleLogin": "Помилка авторизації через Google",
    "ErrorTelegramLogin": "Помилка авторизації через Telegram",
    "ErrorFacebookLogin": "Помилка при авторизації через Facebook",
    "EMailOrPhone": "Введіть правильний телефон або пошту",
    "withoutName": "Без імені",
    "goToCabinet": "У кабінет",
    "Logout": "Завершити сеанс",
    "creationDate": "Дата створення",
    "preparingCompany1": "Створюємо вашу компанію, будь ласка, дайте нам хвилинку.",
    "preparingCompany2": "Готуємо інтерфейс для керування товарами та послугами.",
    "preparingCompany3": "Працюємо над зручним обліком фінансів.",
    "preparingCompany4": "Виконуємо фінальні налаштування, майже готові."
}

export default function() {
    return lang;
}