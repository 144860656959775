const lang = {
    "EnterWidth": "تسجيل الدخول مع:",
    "Registration": "يسجل",
    "enter": "ليأتي",
    "EntrWidthEmailOrPhone": "تسجيل الدخول عبر البريد الإلكتروني أو الهاتف",
    "login": "تفويض",
    "NoAkkGoReg": "ليس لديك حساب؟ يسجل",
    "Password": "كلمه السر",
    "Name": "اسم",
    "LastName": "اسم العائلة",
    "Phone": "هاتف",
    "Email": "بريد",
    "RepeatPassword": "اعد كلمة السر",
    "Сancel": "يلغي",
    "Save": "يحفظ",
    "Language": "لغة",
    "ConfirmThisMail": "تم تأكيد بريدك الإلكتروني",
    "Saved": "أنقذ",
    "Error": "خطأ",
    "FillInAllTheFields": "املأ جميع الحقول",
    "Next": "إضافي",
    "MinPassLength": "الحد الأدنى لطول كلمة المرور 4 أحرف",
    "ForgotYourPassword": "نسيت رقمك السري",
    "RestorePassword": "استعادة كلمة السر",
    "UserMustBeRegistered": "يجب أن يكون المستخدم مسجلا",
    "Confirm": "يتأكد",
    "WrongFormat": "صيغة خاطئة",
    "WrongPassword": "كلمة سر خاطئة",
    "WrongLoginOrPassword": "خطا في اسم الدخول او في كلمه المرور",
    "PhoneAlreadyRegistered": "الهاتف مسجل بالفعل",
    "EmailAlreadyRegistered": "البريد مسجل بالفعل",
    "FillInTheFieldsCorrectly": "املأ الحقول بشكل صحيح",
    "ThisAccountDoesNotExist": "هذا الحساب غير موجود",
    "LinkIsNotValid": "الارتباط غير صالح",
    "AMessageHasBeenSentToTheSpecifiedLogin": "تم إرسال رسالة إلى اسم المستخدم المحدد",
    "IncorrectCaptcha": "كلمة التحقق خاطئة",
    "CaptchaIsNotValid": "لم تعد كلمة التحقق صالحة",
    "EnterCaptcha": "أدخل كلمة التحقق",
    "EnterSMS": "أدخل الرمز من SMS",
    "SMSCodeIsNoLongerValid": "لم يعد رمز الرسائل القصيرة صالحًا",
    "SMSCodeIsIncorrect": "رمز SMS غير صحيح",
    "MailNotVerifiedLinkTimedOut": "لم يتم تأكيد البريد ، انتهت صلاحية الرابط",
    "DemoLogin": "تسجيل الدخول التجريبي",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "هل أنت متأكد من أنك تريد الدخول إلى <b> العرض التوضيحي </b> لتجربته؟",
    "FillInAdditionalInformation": "املأ المعلومات الإضافية",
    "ToCompleteRegistration": "لإتمام التسجيل",
    "LinkThirdPartyServices": "ربط خدمات الطرف الثالث",
    "Complete": "لإكمال",
    "AccountAlreadyLinked": "الحساب مرتبط بالفعل",
    "AccountNotRegistered": "الحساب غير مسجل",
    "NotMyNumber": "ليس رقم هاتفي",
    "Back": "خلف",
    "numberOfAttemptsToCheckAuth": "عدد محاولات تسجيل الدخول:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "كلمة مرور خاطئة ، عدد محاولات تسجيل الدخول:",
    "TryAgainAfterMinute": "يرجى المحاولة مرة أخرى بعد دقيقة",
    "Help": "يدعم",
    "y": "نعم",
    "n": "لا",
    "doYouConfirmEmail": "هل أنت متأكد أنك تريد تغيير بريدك الإلكتروني؟",
    "success": "بنجاح",
    "goToAutorize": "انتقل إلى إذن",
    "UserEmailAlreadyExists": "المستخدم مع هذا البريد الإلكتروني موجود بالفعل",
    "UserPhoneAlreadyExists": "المستخدم برقم الهاتف هذا موجود بالفعل",
    "TermOfUse": "تعليمات الاستخدام",
    "PrivacyPolicy": "سياسة الخصوصية",
    "accountNotRegistered": "الحساب غير مسجل",
    "wantToReg": "هل تريد التسجيل؟",
    "weSuccessChangeYouEmail": "لقد نجحنا في تغيير عنوان بريدك الإلكتروني",
    "youCanCloseThisWindow": "يمكنك إغلاق هذه النافذة",
    "countryConfirm": "تأكيد البلد",
    "EmailAlreadyTied": "البريد مرتبط بالفعل بحساب آخر",
    "areYourCountry": "هل هذا بلدك",
    "EmergencyEntrance": "الإذن القديم",
    "enterCorrectNumber": "أدخل الرقم بالصيغة الدولية",
    "thisPhoneRegister": "رقم الهاتف هذا مسجل بالفعل في النظام",
    "wantUsePhoneForAuth": "هل ترغب في تسجيل الدخول باستخدام رقم الهاتف هذا؟",
    "ok": "نعم",
    "regAgree": "بتسجيل حساب ، أوافق",
    "wantBind": "تريد تسجيل الدخول وربط الخدمة",
    "bind": "ربط",
    "yoursMailBinded": "بريدك الإلكتروني في حساب موجود",
    "wantUsePhoneAndBind": "مسجل بالفعل في النظام",
    "notGetDataFromApple": "فشل في تلقي البيانات من أبل",
    "ErrorAppleLogin": "خطأ في الترخيص عبر Apple",
    "ErrorGoogleLogin": "خطأ في التفويض عبر Google",
    "ErrorTelegramLogin": "خطأ أثناء تسجيل الدخول عبر تيليجرام",
    "ErrorFacebookLogin": "خطأ في تسجيل الدخول عبر الفيسبوك",
    "EMailOrPhone": "الرجاء إدخال رقم هاتف أو عنوان بريد إلكتروني صالح.",
    "withoutName": "لا يوجد اسم",
    "goToCabinet": "الى المكتب",
    "Logout": "نهاية الجلسة",
    "creationDate": "تاريخ الإنشاء",
    "preparingCompany1": "نحن نقوم بإنشاء شركتك، من فضلك أعطنا دقيقة واحدة...",
    "preparingCompany2": "نحن نقوم بإعداد واجهة لإدارة السلع والخدمات...",
    "preparingCompany3": "نحن نعمل على المحاسبة المالية المريحة...",
    "preparingCompany4": "نحن نقوم بالإعدادات النهائية، وهي جاهزة تقريبًا..."
}

export default function() {
    return lang;
}