<template>
  <smallModal v-if="show == true" @escape="close">
    <companyList :data="data" @goToCompany="goToCompany" @logout="logout"></companyList>
  </smallModal>
</template>
<script>
import companyList from "@/components/choiceCompany/companyList.vue"
import smallModal from '@/components/modals/smallModal.vue';
import Cookies from 'js-cookie';
import { store } from "@/store.js";
import { cabinetAPi } from '@/API.js';
const apiList = new cabinetAPi();
let apiCookies = Cookies;
if (process.env.NODE_ENV == 'production') {
   apiCookies = Cookies.withAttributes({domain: ".skyservice.online"})
}

export default {
  data() {
    return {
      show: false,
      data: ""
    }
  },
  created() {
    this.setLocation()
    let token = Cookies.get("token");
    if (token) {
      this.getCompanies();
    }
  },
  components: {
    companyList,
    smallModal,
  },
  computed:{
    lang() {
        return window.lang
    },
    phrasesArray() {
            return [
                this.lang['preparingCompany1'],
                this.lang['preparingCompany2'],
                this.lang['preparingCompany3'],
                this.lang['preparingCompany4']
            ]
        },

  },
  methods: {
    setLocation(){
      //Поскольку тут мы уже авторизированы то запоминаем что мы хотим редиректится сюда
      let currentLocation = {
          project:  "auth.skyservice.online"
      };

      apiCookies.set("location", JSON.stringify(currentLocation), { expires: 180 })


      try{
            let iosData = {
                location:currentLocation
            }
            window.webkit.messageHandlers.saveUserData.postMessage(JSON.stringify(iosData));
        }catch(err){
            //не приложение ios
        }


    },
    getCompanies() { // получаем данные по персоне




      try {
        apiList.getCompanies().then(resp => {

          if (resp.data.status == 'done') {
            if (resp.data) { // После получения данных о компании передаем их в чат

              store.lang = resp.data.data.lang;

              if (resp.data.data.companies.length == 1) {
                store.loader = true;
                //Якщо компанія одна то автоматично заходим в неї
                this.goToCompany(resp.data.data.companies[0])
              }else if(resp.data.data.companies.length <= 0){
                store.loader = true;
                document.location.href = "https://cabinet.skyservice.online";
              } else if (resp.data.data.companies.length > 1) {
                //Якщо більше 1-ї компанії то показуємо список
                this.data = resp.data.data;
                this.show = true;
              }

            }

          } else if (resp.data.error == "Lost connection to Redis server." || resp.data.error == "socket error on read socket") {
            if (document.referrer) {
              let status = confirm("Authorization temporary is unavailable. Go back to: " + document.referrer);
              if (status) {
                document.location.href = document.referrer;
              } else {
                this.gotoAuth();
              }
            } else {
              // в случае ошибки кикаем токен, если он имеется и редиректим на страницу логина
              this.gotoAuth();
            }
            //Если упал редис то не редиректим на авторизацию
          } else {
            // в случае ошибки кикаем токен, если он имеется и редиректим на страницу логина
            this.gotoAuth();
          }
        }).catch(err => {
          console.error(err)
          window.say.messageError(err);
        })
      } catch (err) {
        window.say.messageError(this.lang['Error']);
      }
    },
    gotoAuth() {
            //удаляем токен
            if (Cookies.get("token")) {
                apiCookies.set("token", '', { expires: -1 });
                //store.token = null;
            }
            document.location.href = 'https://auth.skyservice.online/';
        },
    startPrepareCompany(status) {
      if (status === 'end') {
        store.loaderText = '';
        store.loader = false;
        return;
      } else {
        const outputPhrases = (index) => {
          let delay = index === 0 ? 2000 : 3000;
          if (index < this.phrasesArray.length) {
            store.loader = false;
            setTimeout(() => {
              store.loader = true;
              store.loaderText = this.phrasesArray[index];
                setTimeout(() => {
                  outputPhrases(index + 1);
                  console.log("delay", delay)
                  console.log('index', index)
                }, delay);
              }, 0);
          }
        };
        outputPhrases(0);
      }
    },
    goToCompany(e) {
      if (e.blocked) {
        window.say.messageError(this.lang['companyBlocked'])
      } else if (this.sortList) {
        console.log("В режиме сортировки вход в компанию не разрешен")
      } else {
        try {
          if (e.is_new) {
            console.warn(e)
            this.show = false;
            this.startPrepareCompany('start')
          }
          apiList.redirectPerson({ token: Cookies.get("token"), company_id: e.company_id }).then(resp => {
            store.loader = true;
            console.log(resp)
            if (e.is_new) {
              console.warn(e)
              this.startPrepareCompany('end');
            }
            if (resp.data.status == 'done') {
              // Костыльчик для старого сервиса
              // Скорей всего это решение идеально для интеграции - 
              // со сторонними сервисами и более предпочтительное чем то что было до этого
              // Поскольку мы хотим знать где был последний раз клиент 
              // и при следующем входе его туда обратно перебрасывать
              // то нам нужно запомнить в куки location
              // но сделать это так как мы сделали в новой админке не получится так как с 
              // online.skyservice.pro у нас нету доступа к кукам .skyservice.online
              // по этому запоминаем куда мы перешли здесь в случае если это старый сервер
              if (e.company_server == "s-1") {
                let currentLocation = {
                  project: "online.skyservice.pro"
                };

                apiCookies.set("location", JSON.stringify(currentLocation), { expires: 180 })


                try{
                    let iosData = {
                        location:currentLocation
                    }
                    window.webkit.messageHandlers.saveUserData.postMessage(JSON.stringify(iosData));
                }catch(err){
                    //не приложение ios
                }


              }

              if (e.company_server == "s-5") {
                let currentLocation = {
                  project: "online2.skyservice.pro"
                };

                apiCookies.set("location", JSON.stringify(currentLocation), { expires: 180 })


                try{
                    let iosData = {
                        location:currentLocation
                    }
                    window.webkit.messageHandlers.saveUserData.postMessage(JSON.stringify(iosData));
                }catch(err){
                    //не приложение ios
                }


              }
              location.href = resp.data.data;
            } else if (resp.data.error == 'worker is inactive') {
              window.say.messageError(this.lang['youBlockedInComp']);
            } else {
              this.getData();
              window.say.messageError(this.lang['Error']);
            }
          })
        } catch (err) {
          window.say.messageError(this.lang['Error']);
        }
      }
    },
    logout() {
      try {
        // при выходе кикаем токен, закрываем модалку, ну и выкидываем на страницу логина
        apiList.logout({ token: Cookies.get("token") }).then(resp => {
          if (resp.data.status == 'done') {
            apiCookies.set("token", '', { expires: -1 });
            return
          } else {
            window.say.messageError(this.lang['Error']);
          }
        }).then(() => {
          // this.$emit('logout');
          // this.$router.push('/');
          location.reload(true);
          //document.location.href = 'https://auth.skyservice.online/';
        });
      } catch (err) {
        console.log(err)
      }
    },
    close() {
      this.show = false
    }
  }
}
</script>