<template>
  <div id="app">
      <div id="con">
        <loader v-if="isloader" />
        <router-view></router-view>

        <!--Портал для диалоговых окон-->
        <portal-target name="dialog" style="position:absolute;" multiple />

        <!--Портал для диалоговых окон-->
        <portal-target name="modal" style="position:absolute;" />

        <notifications group="foo" position="bottom right" style="z-index: 10000000 !important;" />
    </div>
  </div>
</template>

<script>
import { handleResize } from "@/main.js";
import { store } from './store';
import { androidFixMono } from './components/js/androidFixMono';
import loader from './components/Loader';

import { cabinetAPi } from '@/API.js';
const apiList = new cabinetAPi();

import Cookies from 'js-cookie';
let apiCookies = Cookies;
if (process.env.NODE_ENV == 'production') {
   apiCookies = Cookies.withAttributes({domain: ".skyservice.online"})
}

// Перехват token из админки
const inParams = new URLSearchParams(location.search);

export default {
    name: 'App',
    components: {
        loader,
    },
    data() {
        return {
            //defaultLang: 'en',
            time: 0,
            show_settings: false,
        }
    },
    beforeCreate() {

        try {
            // const token = Cookies.get("token");
            const accountSettings = inParams.get('accountSettings');

            // if(token) {
            //     //разавторизируем пользователя и кидаем на форму авторизации
            //     //localStorage.removeItem('token');
            //     apiCookies.set("token", '', {expires: -1});
            //     this.$router.push('/');
            // }

            if(accountSettings){
                // Открытие настроек аккаунта
                localStorage.setItem('show_settings_modal', true);
            }
        }catch(err) {
            console.error(err);
        }


        //Берем номер телефону який передали з іншого місця в URL
        try {
            const accountPhone = inParams.get('phone');

            if(accountPhone){
                // вставляєм номер телефону
                localStorage.setItem('user_login', accountPhone);
            }
        }catch(err) {
            console.error(err);
        }
    },
    created() {

        try {
            // const token = Cookies.get("token");
            const isLogout = inParams.get('logout');

            if(isLogout){
                this.logout();
            }
        }catch(err) {
            console.error(err);
        }
        
        store.window.width = window.innerWidth;
        store.window.height = window.innerHeight;
    
        window.addEventListener('resize', this.handleResize);
        androidFixMono('con');
        this.getRef();
    },
    mounted() {
        androidFixMono('con');
        if(localStorage.getItem('show_settings_modal')) {
            this.show_settings = true;
        }
    },
    updated() {
        androidFixMono('con');
    },
    methods: {
            logout() {
                store.loader = true;
                try {
                    // при выходе кикаем токен, закрываем модалку, ну и выкидываем на страницу логина
                    apiList.logout({ token: Cookies.get("token") }).then(resp => {
                    if (resp.data.status == 'done') {
                        apiCookies.set("token", '', { expires: -1 });
                        return
                    } else {
                        window.say.messageError(this.lang['Error']);
                    }
                    }).then(() => {
                    // this.$emit('logout');
                     this.$router.push('/');
                     store.loader = false;
                    //location.reload(true);
                    //document.location.href = 'https://auth.skyservice.online/';
                    });
                } catch (err) {
                    store.loader = false;
                    console.log(err)
                }
            },
            saveDataWindowsApp() {
            let data = {
                token: "test",
                location: {
                    project:"test"
                }
            }
            var request = {
                method: "saveUserData",
                data: JSON.stringify(data)
            }

            window.cefQuery({request: JSON.stringify(request),
                persistent: true,
                onSuccess: function(responseJson) {
                    console.log(responseJson)
                },
                onFailure: function(error_code, error_message) {
                    console.log(error_code, error_message)
                }
            });
        },
        getDataWindowsApp() {
            var request = {
                method: "getUserData"
            }

            window.cefQuery({request: JSON.stringify(request),
                persistent: true,
                onSuccess: function(responseJson) {
                    alert(responseJson);
                    console.log(responseJson)
                },
                onFailure: function(error_code, error_message) {
                    console.log(error_code, error_message)
                }
            });
        },
        handleResize() {
            androidFixMono('con');
            store.window.width = window.innerWidth;
            store.window.height = window.innerHeight;
            if (this.time) clearTimeout(this.time);

            this.time = setTimeout(() => {
                handleResize.$emit('handleResize');
            }, 500);
        },
         // записываем ключ с реферальной ссылки
        getRef() {
            const url = window.location.href;
            const linkParams = (new URL(url)).searchParams; 
            // ретерним объект в котором помещаем выдранные из ссылки значения
            const ref_id = linkParams.get('ref');
            // проверяем не является ли null переменная, если в ней что то есть, тогда делаем запись в локалсторедж
            if (ref_id != null) {
                localStorage.setItem('ref_id', ref_id);
            }
        },
    },
    computed: {
        isloader() {
            return store.loader;
        },
        lang() {
            return window.lang;
        }
    }
}
</script>

<style>
    /*Штука что б через js определить что есть выкушеный дисплей*/
    :root {
        --sat: env(safe-area-inset-top);
        --sar: env(safe-area-inset-right);
        --sab: env(safe-area-inset-bottom);
        --sal: env(safe-area-inset-left);
    }

    html {
        overflow: hidden;
    }
    body {
        padding: 0;
        margin: 0;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow: auto;/* Если кто-то опять захочет починить баг убрав это свойство предупреждаю что сломается прокрутка в политике конфиденциальности */
        height: 100vh;
    }

    #loginContainerBox{
        height: calc(100vh - 70px);
    }

    input {
        margin-bottom: 1rem !important;
    }
    input:focus, button:focus, select:focus {
        outline: none !important;
        border-color: none !important;
        box-shadow: none !important;
    }
    .buttonitem {
        white-space: nowrap; /* Отменяем перенос текста */
        overflow: hidden; /* Обрезаем содержимое */
        text-overflow: ellipsis; /* Многоточие */
        width: 100%;
    }
    .buttonitem button{
        padding: 8px 3px;
    }
    .buttonitem:not(:first-child) {
        padding: 0 0 0 10px;
    }

    /*Только айфон 10, 11 и тд*/
    @media only screen and     
    (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3), /*X, XS, 11pro, 12 Mini, 13 mini*/
    (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3), /*XS Max, 11 Pro Max*/
    (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2), /*XR, 11*/
    (device-width : 428px) and (device-height : 926px) and (-webkit-device-pixel-ratio : 3), /*12 Pro Max. 13 pro max, 14 plus*/
    (device-width : 390px) and (device-height : 844px) and (-webkit-device-pixel-ratio : 3), /*12, 12 Pro, 13, 13 pro, 14*/
    (device-width : 393px) and (device-height : 852px) and (-webkit-device-pixel-ratio : 3), /*14 pro*/ 
    (device-width : 430px) and (device-height : 932px) and (-webkit-device-pixel-ratio : 3) /*14 pro max*/ 
    {


        @media screen and (orientation: portrait) { 
            .green-logo {
                height: 10vh;
            }
            #headerContiner-green, #headerContiner-white { 
                padding-top: env(safe-area-inset-top) !important;    
            }
            #headerContiner-white{
                height: calc(env(safe-area-inset-top) + 60px) !important;      
            }
            .content {
                padding-top:calc(env(safe-area-inset-top) - 20px) !important;  
                padding-bottom:10px;
            }
            .span-container {
                padding-bottom: env(safe-area-inset-bottom, 1rem) !important;
            }
            .addScroll {
                /* height: calc(100vh - 300px) !important; */
                margin-bottom: 5rem;
            }
            .form-reg-block {
                margin-bottom: 0 !important;
            }

            #loginContainerBox{
                height: calc(100vh - calc(env(safe-area-inset-top) + 70px));
            }

            .reg-scroll {
                height: calc(100vh - calc(env(safe-area-inset-top) + 70px)) !important;
      
            }


        }
        @media screen and (orientation: landscape) {    
            #headerContiner-green, .start-modal-img {
                display: none;
            }
        }
    }

    /*Только айфон Xr, 11, 11pro Max, Xs Max*/

    @media only screen and     
    (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3), /*X, XS, 11pro, 12 Mini, 13 mini*/
    (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3), /*XS Max, 11 Pro Max*/
    (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2), /*XR, 11*/
    (device-width : 428px) and (device-height : 926px) and (-webkit-device-pixel-ratio : 3), /*12 Pro Max. 13 pro max, 14 plus*/
    (device-width : 390px) and (device-height : 844px) and (-webkit-device-pixel-ratio : 3), /*12, 12 Pro, 13, 13 pro, 14*/
    (device-width : 393px) and (device-height : 852px) and (-webkit-device-pixel-ratio : 3), /*14 pro*/ 
    (device-width : 430px) and (device-height : 932px) and (-webkit-device-pixel-ratio : 3) /*14 pro max*/ 
    {

        @media screen and (orientation: portrait) { 
            .green-logo {
                height: 10vh;
            }
            #headerContiner-green, #headerContiner-white { 
                padding-top: env(safe-area-inset-top) !important;    
            }
            #headerContiner-white{
                height: calc(env(safe-area-inset-top) + 60px) !important;      
            }
            .content {
                padding-top:calc(env(safe-area-inset-top) - 20px) !important;  
                padding-bottom:10px;
            }
            .span-container {
                padding-bottom: env(safe-area-inset-bottom, 1rem) !important;
            }
            .addScroll {
                /* height: calc(100vh - 300px) !important; */
                margin-bottom: 5rem;
            }
            .form-reg-block {
                margin-bottom: 0 !important;
            }
        }
        @media screen and (orientation: landscape) {    
            #headerContiner-green, .start-modal-img {
                display: none;
            }
        }
    }
</style>
