const lang = {
    "EnterWidth": "Შესვლა:",
    "Registration": "რეგისტრაცია",
    "enter": "Შემოსვლა",
    "EntrWidthEmailOrPhone": "შედით ელექტრონული ფოსტით ან ტელეფონით",
    "login": "ავტორიზაცია",
    "NoAkkGoReg": "არ გაქვთ ანგარიში? რეგისტრაცია",
    "Password": "პაროლი",
    "Name": "სახელი",
    "LastName": "გვარი",
    "Phone": "ტელეფონი",
    "Email": "ფოსტა",
    "RepeatPassword": "გაიმეორეთ პაროლი",
    "Сancel": "გაუქმება",
    "Save": "Გადარჩენა",
    "Language": "Ენა",
    "ConfirmThisMail": "თქვენი ელფოსტა დადასტურდა",
    "Saved": "შენახულია",
    "Error": "შეცდომა",
    "FillInAllTheFields": "შეავსეთ ყველა ველი",
    "Next": "Უფრო",
    "MinPassLength": "პაროლის მინიმალური სიგრძე 4 სიმბოლო",
    "ForgotYourPassword": "დაგავიწყდათ პაროლი",
    "RestorePassword": "პაროლის აღდგენა",
    "UserMustBeRegistered": "მომხმარებელი უნდა იყოს დარეგისტრირებული",
    "Confirm": "დაადასტურეთ",
    "WrongFormat": "Არასწორი ფორმატი",
    "WrongPassword": "არასწორი პაროლი",
    "WrongLoginOrPassword": "არასწორი შესვლა ან პაროლი",
    "PhoneAlreadyRegistered": "ტელეფონი უკვე დარეგისტრირებულია",
    "EmailAlreadyRegistered": "ფოსტა უკვე დარეგისტრირებულია",
    "FillInTheFieldsCorrectly": "სწორად შეავსეთ ველები",
    "ThisAccountDoesNotExist": "ეს ანგარიში არ არსებობს",
    "LinkIsNotValid": "ბმული არასწორია",
    "AMessageHasBeenSentToTheSpecifiedLogin": "შეტყობინება გაიგზავნა მითითებულ მომხმარებლის სახელზე",
    "IncorrectCaptcha": "არასწორი captcha",
    "CaptchaIsNotValid": "Captcha აღარ მოქმედებს",
    "EnterCaptcha": "შეიყვანეთ captcha",
    "EnterSMS": "შეიყვანეთ კოდი SMS-დან",
    "SMSCodeIsNoLongerValid": "SMS კოდი აღარ არის მოქმედი",
    "SMSCodeIsIncorrect": "SMS კოდი არასწორია",
    "MailNotVerifiedLinkTimedOut": "ფოსტა არ არის დადასტურებული, ბმული ამოიწურა",
    "DemoLogin": "დემო შესვლა",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "დარწმუნებული ხართ, რომ გსურთ შეხვიდეთ <b> დემო </b> საცდელად?",
    "FillInAdditionalInformation": "შეავსეთ დამატებითი ინფორმაცია",
    "ToCompleteRegistration": "რეგისტრაციის დასასრულებლად",
    "LinkThirdPartyServices": "დააკავშირეთ მესამე მხარის სერვისები",
    "Complete": "Დასრულება",
    "AccountAlreadyLinked": "ანგარიში უკვე დაკავშირებულია",
    "AccountNotRegistered": "ანგარიში არ არის რეგისტრირებული",
    "NotMyNumber": "ჩემი ნომერი არაა",
    "Back": "უკან",
    "numberOfAttemptsToCheckAuth": "შესვლის მცდელობების რაოდენობა:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "არასწორი პაროლი, შესვლის მცდელობების რაოდენობა:",
    "TryAgainAfterMinute": "გთხოვთ, სცადოთ ხელახლა ერთ წუთში",
    "Help": "მხარდაჭერა",
    "y": "დიახ",
    "n": "არა",
    "doYouConfirmEmail": "დარწმუნებული ხართ, რომ გსურთ შეცვალოთ თქვენი ელფოსტა?",
    "success": "Წარმატებით",
    "goToAutorize": "გადადით ავტორიზაციაზე",
    "UserEmailAlreadyExists": "მომხმარებელი ამ ელფოსტით უკვე არსებობს",
    "UserPhoneAlreadyExists": "მომხმარებელი ამ ტელეფონის ნომრით უკვე არსებობს",
    "TermOfUse": "Მოხმარების პირობები",
    "PrivacyPolicy": "Კონფიდენციალურობის პოლიტიკა",
    "accountNotRegistered": "ანგარიში არ არის რეგისტრირებული",
    "wantToReg": "გსურთ დარეგისტრირება?",
    "weSuccessChangeYouEmail": "ჩვენ წარმატებით შევცვალეთ თქვენი ელფოსტის მისამართი",
    "youCanCloseThisWindow": "შეგიძლიათ დახუროთ ეს ფანჯარა",
    "countryConfirm": "ქვეყნის დადასტურება",
    "EmailAlreadyTied": "ფოსტა უკვე დაკავშირებულია სხვა ანგარიშთან",
    "areYourCountry": "ეს შენი ქვეყანაა?",
    "EmergencyEntrance": "ძველი ავტორიზაცია",
    "enterCorrectNumber": "შეიყვანეთ ნომერი საერთაშორისო ფორმატში",
    "thisPhoneRegister": "ტელეფონის ეს ნომერი უკვე რეგისტრირებულია სისტემაში",
    "wantUsePhoneForAuth": "გსურთ შეხვიდეთ ამ ტელეფონის ნომრის გამოყენებით?",
    "ok": "კარგი",
    "regAgree": "ანგარიშის რეგისტრაციით ვეთანხმები",
    "wantBind": "გსურთ შეხვიდეთ და დააკავშიროთ სერვისი",
    "bind": "შებოჭვა",
    "yoursMailBinded": "თქვენი ელფოსტა უკვე არსებულ ანგარიშშია",
    "wantUsePhoneAndBind": "სისტემაში უკვე რეგისტრირებულია",
    "notGetDataFromApple": "Apple-ისგან მონაცემების მიღება ვერ მოხერხდა",
    "ErrorAppleLogin": "Apple-ის მეშვეობით ავტორიზაციის შეცდომა",
    "ErrorGoogleLogin": "Google-ის მეშვეობით ავტორიზაციის შეცდომა",
    "ErrorTelegramLogin": "შეცდომა Telegram-ით შესვლისას",
    "ErrorFacebookLogin": "შეცდომა Facebook-ით შესვლისას",
    "EMailOrPhone": "გთხოვთ, შეიყვანოთ სწორი ტელეფონის ნომერი ან ელფოსტის მისამართი.",
    "withoutName": "სახელი არ არის",
    "goToCabinet": "ოფისში",
    "Logout": "სესიის დასრულება",
    "creationDate": "შექმნის თარიღი",
    "preparingCompany1": "ჩვენ ვქმნით თქვენს კომპანიას, გთხოვთ დაგვიტოვოთ ერთი წუთი...",
    "preparingCompany2": "ჩვენ ვამზადებთ ინტერფეისს საქონლისა და სერვისების მართვისთვის...",
    "preparingCompany3": "ჩვენ ვმუშაობთ მოხერხებულ ფინანსურ აღრიცხვაზე...",
    "preparingCompany4": "ჩვენ ვაკეთებთ საბოლოო პარამეტრებს, თითქმის მზად..."
}

export default function() {
    return lang;
}