const lang = {
    "EnterWidth": "Увайсці праз:",
    "Registration": "Зарэгістравацца",
    "enter": "Увайсці",
    "EntrWidthEmailOrPhone": "Увайсці праз пошту ці тэлефон",
    "login": "Аўтарызацыя",
    "NoAkkGoReg": "Няма акаўнта? Зарэгіструйцеся",
    "Password": "Пароль",
    "Name": "Імя",
    "LastName": "Прозвішча",
    "Phone": "Тэлефон",
    "Email": "Пошта",
    "RepeatPassword": "Паўтарыць пароль",
    "Сancel": "Адмена",
    "Save": "Захаваць",
    "Language": "Мова",
    "ConfirmThisMail": "Ваш e-mail пацверджаны",
    "Saved": "Захавана",
    "Error": "Памылка",
    "FillInAllTheFields": "Запоўніце ўсе палі",
    "Next": "Далей",
    "MinPassLength": "Мінімальная доўгая пароля 4 сімвалаў",
    "ForgotYourPassword": "Забыліся пароль",
    "RestorePassword": "Аднавіць пароль",
    "UserMustBeRegistered": "Карыстальнік павінен быць зарэгістраваны",
    "Confirm": "Пацвердзіць",
    "WrongFormat": "Няверны фармат",
    "WrongPassword": "Няверны пароль",
    "WrongLoginOrPassword": "Няправільны лагін ці пароль",
    "PhoneAlreadyRegistered": "Тэлефон ужо зарэгістраваны",
    "EmailAlreadyRegistered": "Пошта ўжо зарэгістраваная",
    "FillInTheFieldsCorrectly": "Карэктна запоўніце палі",
    "ThisAccountDoesNotExist": "Дадзены акаўнт не існуе",
    "LinkIsNotValid": "Спасылка не сапраўдная",
    "AMessageHasBeenSentToTheSpecifiedLogin": "На ўказаны лагін адпраўлена паведамленне",
    "IncorrectCaptcha": "Няправільная капча",
    "CaptchaIsNotValid": "Капча больш несапраўдная",
    "EnterCaptcha": "Увядзіце капчу",
    "EnterSMS": "Увядзіце код з СМС",
    "SMSCodeIsNoLongerValid": "СМС код ужо не сапраўдны",
    "SMSCodeIsIncorrect": "СМС код няправільны",
    "MailNotVerifiedLinkTimedOut": "Пошта не пацверджана, скончыўся час спасылкі",
    "DemoLogin": "Дэма ўваход",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Вы сапраўды хочаце ўвайсці ў <b> дэманстрацыйную версію </b> для азнаямлення?",
    "FillInAdditionalInformation": "Запоўніць дадатковую інфармацыю",
    "ToCompleteRegistration": "Для завяршэння рэгістрацыі",
    "LinkThirdPartyServices": "Прывязаць іншыя сэрвісы",
    "Complete": "Завяршыць",
    "AccountAlreadyLinked": "Акаўнт ужо прывязаны",
    "AccountNotRegistered": "Акаўнт не зарэгістраваны",
    "NotMyNumber": "Не мой нумар",
    "Back": "Назад",
    "numberOfAttemptsToCheckAuth": "Колькасць спроб для ўваходу:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Няверны пароль, колькасць спроб для ўваходу:",
    "TryAgainAfterMinute": "Паўтарыце спробу праз хвіліну",
    "Help": "Тэхпадтрымка",
    "y": "Так",
    "n": "Не",
    "doYouConfirmEmail": "Вы сапраўды хочаце змяніць вашу электронную пошту?",
    "success": "Паспяхова",
    "goToAutorize": "Перайсці да аўтарызацыі",
    "UserEmailAlreadyExists": "Карыстальнік з такой поштай ужо існуе",
    "UserPhoneAlreadyExists": "Карыстальнік з такім тэлефонам ужо існуе",
    "TermOfUse": "Умовы выкарыстання",
    "PrivacyPolicy": "Палітыка прыватнасці",
    "accountNotRegistered": "рахунак не зарэгістраваны",
    "wantToReg": "Жадаеце зарэгістравацца?",
    "weSuccessChangeYouEmail": "Мы паспяхова змянілі ваш адрас электроннай пошты",
    "youCanCloseThisWindow": "Вы можаце зачыніць гэтае акно",
    "countryConfirm": "Пацвярджэнне краіны",
    "EmailAlreadyTied": "Пошта ўжо прывязана да іншага акаўнта",
    "areYourCountry": "гэта ваша краіна?",
    "EmergencyEntrance": "Старая аўтарызацыя",
    "enterCorrectNumber": "Увядзіце нумар у міжнародным фармаце",
    "thisPhoneRegister": "Такі нумар тэлефона ўжо зарэгістраваны ў сістэме",
    "wantUsePhoneForAuth": "Жадаеце аўтарызавацца выкарыстоўваючы гэты нумар тэлефона?",
    "ok": "Ок",
    "regAgree": "Рэгіструючы акаўнт, я прымаю",
    "wantBind": "Жадаеце ўвайсці ў рахунак і прывязаць сэрвіс",
    "bind": "Прывязаць",
    "yoursMailBinded": "Ваша пошта пазначана ў наяўным акаўнце",
    "wantUsePhoneAndBind": "ужо зарэгістраваны ў сістэме",
    "notGetDataFromApple": "Не ўдалося атрымаць дадзеныя ад Apple",
    "ErrorAppleLogin": "Памылка пры аўтарызацыі праз Apple",
    "ErrorGoogleLogin": "Памылка пры аўтарызацыі праз Google",
    "ErrorTelegramLogin": "Памылка пры аўтарызацыі праз Telegram",
    "ErrorFacebookLogin": "Памылка пры аўтарызацыі праз Facebook",
    "EMailOrPhone": "Увядзіце карэктны тэлефон ці пошту",
    "withoutName": "Без імя",
    "goToCabinet": "У кабінет",
    "Logout": "Завяршыць сеанс",
    "creationDate": "Дата стварэння",
    "preparingCompany1": "Ствараем вашу кампанію, калі ласка, дайце нам хвілінку...",
    "preparingCompany2": "Рыхтуем інтэрфейс для кіравання таварамі і паслугамі...",
    "preparingCompany3": "Працуем над зручным улікам фінансаў...",
    "preparingCompany4": "Выконваем фінальныя наладкі, амаль гатова..."
}

export default function() {
    return lang;
}