<template>
    <smallModal @escape="$emit('close')">
        <div>
            <div class="usr-icon-login-container">
                <div class="user-icon-div">
                    <img src="@/assets/userSVG.svg" class="account-icon" alt="Account Icon">
                </div>
                <span class="person-login">{{ personLogin }}</span>
            </div>
            <hr>
            <div>
                <div v-if="registered">
                    <div class="password">
                        <label for="phone">{{ lang['Password'] }}</label>
                        <b-form-input type="password" v-model="password" :state="statePass" id="pass" autofocus />
                        <span v-if="password.length > 0" @click="showPass" :class="visiblePass ? 'icon-eye' : 'icon-eye-blocked'"></span>
                    </div>
                    <div class="forgot-password">
                        <span @click="forgotPassword">{{ lang['ForgotYourPassword'] }}?</span>
                    </div>
                </div>
                <div v-else>
                    <h5 class="notExistH">{{ lang['ThisAccountDoesNotExist'] }}</h5>
                </div>
            </div>
            <hr>
            <div class="buttons" :style="w > 700 && !registered ? {'width': ' 370px'} : {'width': ''}">
                <button class="btn btn-link cancelButton" @click="cancel">{{ lang['Сancel'] }}</button>
                <button v-if="registered" class="btn btn-success enterButton" @click="save">{{ lang['enter'] }}</button>
                <button v-else class="btn btn-success btn-go-reg" @click="goReg">{{ lang['Registration'] }}</button>
            </div>
        </div>
    </smallModal>
</template>

<script>
import { keyevent } from '@/main.js';
import smallModal from '../modals/smallModal';
import { store } from '@/store.js'
export default {
    props: ['personLogin', 'registered'],
    components: {
       smallModal
    },
    data() {
        return {
           password: '',
           visiblePass: false
        }
    },
    mounted() {
        keyevent.$off('enter');
        keyevent.$on('enter', () => {
            if(this.registered){
                this.statePass ? this.save() : window.say.messageError(this.lang['MinPassLength'])
            } else {
                this.goReg()
            }
        })
    },
    methods: {
        goReg() {
            // если вдруг не зареганный юзер перешел на авторизацию под номером телефона или почтой которых нет в 
            // базе, предлагаем ему зарегестрироваться, попутно записывая введенный им логин
            const mailRegular  = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
            if (mailRegular.test(this.personLogin) == true) {
                this.$emit('close');
                keyevent.$off('enter')
                this.$router.push('/registration?email='+this.personLogin);
            }else{
                this.$emit('close');
                keyevent.$off('enter')
                this.$router.push('/registration?phone='+this.personLogin);
            }
        },
        // если юзер забыл пароль , то направляем его на капчу
        forgotPassword() {
            try {
                this.$emit('goToCaptch');
                this.$emit('close');
            } catch(err) {
                // console.log(err)
            }
        },
        cancel() {
           this.$emit('close')
        },
        //берем пароли при нажатии на кнопку и отправляем его в родителя, там вызываем функцию логина
        save() {
            if (this.statePass) {
                this.$emit('sendPass', this.password);
                // this.$emit('close')
            } else {
                window.say.messageError(this.lang['MinPassLength']);
            }
        },
        showPass(){
            this.visiblePass = !this.visiblePass
            const input = document.getElementById('pass');
            if (input.getAttribute('type') == 'password') {
                input.setAttribute('type', 'text');
            } else {
                input.setAttribute('type', 'password');
            }
        },
    },
    computed: {
        statePass() {
            return this.password.length >= 4 ? true : false;
        },
        lang() {
            return window.lang;
        },
        w() {
            return store.window.width;
        }
    }
}
</script>

<style scoped>
    .btn{
        width: 49%;
    }
    .buttons{
        display: flex;
        justify-content: space-between;
    }
    .forgot-password {
        text-align: right;
        color: #106090; 
        text-decoration: underline; 
        cursor: pointer;
    }
    .usr-icon-login-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .account-icon{
        height: 3rem;
    }
    .notExistH{
        color: grey; 
        text-align: center;
    }
    .user-icon-div {
        /* border: 1px solid gray; */
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 999px;
        margin-bottom: 1rem;
    }
    .person-login {
        font-size: 1.3rem;
        font-weight: 500;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
    }
    .icon-eye, .icon-eye-blocked {
        position: absolute;
        top: 59.5%;
        right: 11.5%;
    }
    .btn-go-reg {
        text-transform: uppercase; 
        font-size: 10pt;
    }
    .cancelButton{
        text-align:left; 
        text-transform: uppercase; 
        font-size: 10pt; 
        color:gray
    }
    .enterButton{
        text-transform: uppercase; 
        font-size: 10pt;
    }
    @media screen and (max-width: 700px) {
        .btn-go-reg {
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .icon-eye, .icon-eye-blocked  {
            //right: 6.5%;
          //right: 10.5%;

        }
    }
    .password{
        position: relative;
    }

    input[type="password"]::-ms-reveal {
      display: none;
    }
</style>