const lang = {
    "EnterWidth": "Logheaza-te cu:",
    "Registration": "Inregistreaza-te",
    "enter": "A intra",
    "EntrWidthEmailOrPhone": "Conectați-vă prin e-mail sau telefon",
    "login": "Autorizare",
    "NoAkkGoReg": "Nu ai un cont? Inregistreaza-te",
    "Password": "Parola",
    "Name": "Nume",
    "LastName": "Nume de familie",
    "Phone": "Telefon",
    "Email": "Poștă",
    "RepeatPassword": "Repetați parola",
    "Сancel": "Anulare",
    "Save": "salva",
    "Language": "Limba",
    "ConfirmThisMail": "E-mailul dvs. a fost confirmat",
    "Saved": "Salvat",
    "Error": "Greşeală",
    "FillInAllTheFields": "Completați toate câmpurile",
    "Next": "Mai departe",
    "MinPassLength": "Lungimea minimă a parolei este de 4 caractere",
    "ForgotYourPassword": "Ați uitat parola",
    "RestorePassword": "Restaurați parola",
    "UserMustBeRegistered": "Utilizatorul trebuie să fie înregistrat",
    "Confirm": "A confirma",
    "WrongFormat": "Format gresit",
    "WrongPassword": "Parola incorecta",
    "WrongLoginOrPassword": "Parola sau username gresit",
    "PhoneAlreadyRegistered": "Telefon deja înregistrat",
    "EmailAlreadyRegistered": "E-mail deja înregistrat",
    "FillInTheFieldsCorrectly": "Completați corect câmpurile",
    "ThisAccountDoesNotExist": "acest cont nu exista",
    "LinkIsNotValid": "Linkul nu este valid",
    "AMessageHasBeenSentToTheSpecifiedLogin": "A fost trimis un mesaj către numele de utilizator specificat",
    "IncorrectCaptcha": "cod captcha gresit",
    "CaptchaIsNotValid": "Captcha nu mai este valabil",
    "EnterCaptcha": "Introduceți captcha",
    "EnterSMS": "Introdu codul din SMS",
    "SMSCodeIsNoLongerValid": "Codul SMS nu mai este valid",
    "SMSCodeIsIncorrect": "Codul SMS este incorect",
    "MailNotVerifiedLinkTimedOut": "E-mailul nu a fost confirmat, linkul a expirat",
    "DemoLogin": "Autentificare demonstrativă",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Sigur doriți să intrați în <b> demonstrație </b> pentru a o încerca?",
    "FillInAdditionalInformation": "Completați informații suplimentare",
    "ToCompleteRegistration": "Pentru a finaliza înregistrarea",
    "LinkThirdPartyServices": "Conectați servicii terțe",
    "Complete": "A termina",
    "AccountAlreadyLinked": "Cont deja conectat",
    "AccountNotRegistered": "Contul nu este înregistrat",
    "NotMyNumber": "Nu numărul meu",
    "Back": "Înapoi",
    "numberOfAttemptsToCheckAuth": "Număr de încercări de conectare:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Parolă greșită, numărul de încercări de conectare:",
    "TryAgainAfterMinute": "Vă rugăm să încercați din nou peste un minut",
    "Help": "A sustine",
    "y": "da",
    "n": "Nu",
    "doYouConfirmEmail": "Sigur doriți să vă schimbați adresa de e-mail?",
    "success": "Cu succes",
    "goToAutorize": "Mergi la autorizare",
    "UserEmailAlreadyExists": "Utilizatorul cu acest e-mail există deja",
    "UserPhoneAlreadyExists": "Utilizatorul cu acest număr de telefon există deja",
    "TermOfUse": "Termeni de utilizare",
    "PrivacyPolicy": "Politica de confidențialitate",
    "accountNotRegistered": "cont neînregistrat",
    "wantToReg": "Doriți să vă înregistrați?",
    "weSuccessChangeYouEmail": "Am schimbat cu succes adresa dvs. de e-mail",
    "youCanCloseThisWindow": "Puteți închide această fereastră",
    "countryConfirm": "Confirmarea țării",
    "EmailAlreadyTied": "E-mailul este deja conectat la alt cont",
    "areYourCountry": "aceasta este tara ta?",
    "EmergencyEntrance": "Veche Autorizare",
    "enterCorrectNumber": "Introduceți numărul în format internațional",
    "thisPhoneRegister": "Acest număr de telefon este deja înregistrat în sistem",
    "wantUsePhoneForAuth": "Doriți să vă conectați folosind acest număr de telefon?",
    "ok": "bine",
    "regAgree": "Prin înregistrarea unui cont, accept",
    "wantBind": "Doriți să vă conectați și să conectați serviciul",
    "bind": "lega",
    "yoursMailBinded": "E-mailul dvs. se află într-un cont existent",
    "wantUsePhoneAndBind": "deja înregistrate în sistem",
    "notGetDataFromApple": "Nu s-a putut primi date de la Apple",
    "ErrorAppleLogin": "Eroare la autorizare prin Apple",
    "ErrorGoogleLogin": "Eroare la autorizare prin Google",
    "ErrorTelegramLogin": "Eroare la conectarea prin Telegram",
    "ErrorFacebookLogin": "Eroare la conectare prin Facebook",
    "EMailOrPhone": "Vă rugăm să introduceți un număr de telefon sau o adresă de e-mail validă.",
    "withoutName": "Fără nume",
    "goToCabinet": "La birou",
    "Logout": "Încheiați sesiunea",
    "creationDate": "Data creării",
    "preparingCompany1": "Vă creăm compania, vă rugăm să ne acordați un minut...",
    "preparingCompany2": "Pregătim o interfață pentru gestionarea bunurilor și serviciilor...",
    "preparingCompany3": "Lucrăm la o contabilitate financiară convenabilă...",
    "preparingCompany4": "Facem setările finale, aproape gata..."
}

export default function() {
    return lang;
}