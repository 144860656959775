const lang = {
    "EnterWidth": "Воридшавӣ бо:",
    "Registration": "Сабти ном",
    "enter": "Барои даромадан",
    "EntrWidthEmailOrPhone": "Воридшавӣ тавассути почтаи электронӣ ё телефон",
    "login": "Иҷозатнома",
    "NoAkkGoReg": "Оё ҳисоб надоред? Сабти ном",
    "Password": "Рамз",
    "Name": "Ном",
    "LastName": "Фамилия",
    "Phone": "Телефон",
    "Email": "Почта",
    "RepeatPassword": "такрори калидвожа",
    "Сancel": "Бекор кардан",
    "Save": "Захира кунед",
    "Language": "Забон",
    "ConfirmThisMail": "Почтаи электронии шумо тасдиқ карда шуд",
    "Saved": "Захира карда шуд",
    "Error": "Хато",
    "FillInAllTheFields": "Ҳама майдонҳоро пур кунед",
    "Next": "Минбаъд",
    "MinPassLength": "Дарозии ҳадди ақали парол 4 аломат",
    "ForgotYourPassword": "Калидвожаатонро фаромӯш кардед",
    "RestorePassword": "Паролро барқарор кунед",
    "UserMustBeRegistered": "Истифодабаранда бояд ба қайд гирифта шавад",
    "Confirm": "Тасдиқ кунед",
    "WrongFormat": "Формат нодуруст",
    "WrongPassword": "Рамзи нодуруст",
    "WrongLoginOrPassword": "логин ё парол нодуруст",
    "PhoneAlreadyRegistered": "Телефон аллакай ба қайд гирифта шудааст",
    "EmailAlreadyRegistered": "Почта аллакай ба қайд гирифта шудааст",
    "FillInTheFieldsCorrectly": "Майдонҳоро дуруст пур кунед",
    "ThisAccountDoesNotExist": "Ин ҳисоб вуҷуд надорад",
    "LinkIsNotValid": "Истинод эътибор надорад",
    "AMessageHasBeenSentToTheSpecifiedLogin": "Ба номи корбарии муайяншуда паём фиристода шуд",
    "IncorrectCaptcha": "Captcha нодуруст",
    "CaptchaIsNotValid": "Captcha дигар эътибор надорад",
    "EnterCaptcha": "captcha ворид кунед",
    "EnterSMS": "Рамзро аз SMS ворид кунед",
    "SMSCodeIsNoLongerValid": "Рамзи SMS дигар эътибор надорад",
    "SMSCodeIsIncorrect": "Рамзи SMS нодуруст аст",
    "MailNotVerifiedLinkTimedOut": "Почта тасдиқ нашудааст, истинод ба охир расид",
    "DemoLogin": "Воридшавӣ намоишӣ",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Оё мутмаин ҳастед, ки мехоҳед ба <b> намоишӣ </b> ворид шавед, то онро санҷед?",
    "FillInAdditionalInformation": "Маълумоти иловагиро пур кунед",
    "ToCompleteRegistration": "Барои ба итмом расонидани сабти ном",
    "LinkThirdPartyServices": "Хидматҳои тарафи сеюмро пайваст кунед",
    "Complete": "Барои пурра кардан",
    "AccountAlreadyLinked": "Ҳисоб аллакай пайваст карда шудааст",
    "AccountNotRegistered": "Ҳисоб ба қайд гирифта нашудааст",
    "NotMyNumber": "Рақами ман нест",
    "Back": "Бозгашт",
    "numberOfAttemptsToCheckAuth": "Шумораи кӯшишҳои воридшавӣ:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Пароли нодуруст, шумораи кӯшишҳои воридшавӣ:",
    "TryAgainAfterMinute": "Лутфан пас аз як дақиқа бори дигар кӯшиш кунед",
    "Help": "Дастгирӣ",
    "y": "Бале",
    "n": "Не",
    "doYouConfirmEmail": "Шумо мутмаин ҳастед, ки мехоҳед почтаи электронии худро тағир диҳед?",
    "success": "бомуваффакият",
    "goToAutorize": "Ба иҷозат равед",
    "UserEmailAlreadyExists": "Корбар бо ин почтаи электронӣ аллакай вуҷуд дорад",
    "UserPhoneAlreadyExists": "Корбар бо ин рақами телефон аллакай вуҷуд дорад",
    "TermOfUse": "Шартҳои истифода",
    "PrivacyPolicy": "Сиёсати Корбурди Маълумоти Шахсӣ",
    "accountNotRegistered": "ҳисоб ба қайд гирифта нашудааст",
    "wantToReg": "Мехоҳед сабти ном шавед?",
    "weSuccessChangeYouEmail": "Мо суроғаи почтаи электронии шуморо бомуваффақият иваз кардем",
    "youCanCloseThisWindow": "Шумо метавонед ин тирезаро пӯшед",
    "countryConfirm": "Тасдиқи кишвар",
    "EmailAlreadyTied": "Почта аллакай ба ҳисоби дигар пайваст шудааст",
    "areYourCountry": "ин кишвари шумост?",
    "EmergencyEntrance": "Иҷозатномаи кӯҳна",
    "enterCorrectNumber": "Рақамро дар формати байналмилалӣ ворид кунед",
    "thisPhoneRegister": "Ин рақами телефон аллакай дар система сабт шудааст",
    "wantUsePhoneForAuth": "Оё шумо мехоҳед бо ин рақами телефон ворид шавед?",
    "ok": "хуб",
    "regAgree": "Бо сабти ҳисоб, ман қабул мекунам",
    "wantBind": "Мехоҳед ворид шавед ва хидматро пайваст кунед",
    "bind": "баста",
    "yoursMailBinded": "Почтаи электронии шумо дар ҳисоби мавҷуда аст",
    "wantUsePhoneAndBind": "аллакай дар система ба қайд гирифта шудааст",
    "notGetDataFromApple": "Қабули маълумот аз Apple натавонист",
    "ErrorAppleLogin": "Хатогии иҷозатдиҳӣ тавассути Apple",
    "ErrorGoogleLogin": "Хатогии иҷозатдиҳӣ тавассути Google",
    "ErrorTelegramLogin": "Хатогӣ ҳангоми ворид шудан тавассути Telegram",
    "ErrorFacebookLogin": "Хатогии воридшавӣ тавассути Facebook",
    "EMailOrPhone": "Лутфан рақами телефон ё суроғаи почтаи электронии дурустро ворид кунед.",
    "withoutName": "Ном нест",
    "goToCabinet": "Ба офис",
    "Logout": "Сеанси хотимавӣ",
    "creationDate": "Санаи офариниш",
    "preparingCompany1": "Мо ширкати шуморо таъсис медиҳем, лутфан ба мо як дақиқа вақт диҳед...",
    "preparingCompany2": "Мо интерфейси идоракунии молҳо ва хидматҳоро омода карда истодаем...",
    "preparingCompany3": "Мо дар бораи баҳисобгирии молиявии қулай кор мекунем...",
    "preparingCompany4": "Мо танзимоти ниҳоӣ месозем, қариб омода..."
}

export default function() {
    return lang;
}