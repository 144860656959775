import { render, staticRenderFns } from "./continueBindSocials.vue?vue&type=template&id=ec490880&scoped=true"
import script from "./continueBindSocials.vue?vue&type=script&lang=js"
export * from "./continueBindSocials.vue?vue&type=script&lang=js"
import style0 from "./continueBindSocials.vue?vue&type=style&index=0&id=ec490880&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec490880",
  null
  
)

export default component.exports