const lang = {
    "EnterWidth": "Συνδέσου με:",
    "Registration": "Κανω ΕΓΓΡΑΦΗ",
    "enter": "Να ερθει μεσα",
    "EntrWidthEmailOrPhone": "Είσοδος μέσω email ή τηλεφώνου",
    "login": "Εξουσιοδότηση",
    "NoAkkGoReg": "Δεν έχετε λογαριασμό; Κανω ΕΓΓΡΑΦΗ",
    "Password": "Κωδικός πρόσβασης",
    "Name": "Ονομα",
    "LastName": "Επώνυμο",
    "Phone": "Τηλέφωνο",
    "Email": "Ταχυδρομείο",
    "RepeatPassword": "επανάληψη κωδικού πρόσβασης",
    "Сancel": "Ματαίωση",
    "Save": "Σώσει",
    "Language": "Γλώσσα",
    "ConfirmThisMail": "Το email σας επιβεβαιώθηκε",
    "Saved": "Αποθηκεύτηκε",
    "Error": "Λάθος",
    "FillInAllTheFields": "Συμπληρώστε όλα τα πεδία",
    "Next": "Περαιτέρω",
    "MinPassLength": "Ελάχιστο μήκος κωδικού πρόσβασης 4 χαρακτήρες",
    "ForgotYourPassword": "Ξεχάσατε τον κωδικό σας",
    "RestorePassword": "Επαναφορά κωδικού πρόσβασης",
    "UserMustBeRegistered": "Ο χρήστης πρέπει να είναι εγγεγραμμένος",
    "Confirm": "Επιβεβαιώνω",
    "WrongFormat": "Λάθος μορφή",
    "WrongPassword": "Λάθος κωδικός",
    "WrongLoginOrPassword": "λάθος είσοδος ή κωδικός πρόσβασης",
    "PhoneAlreadyRegistered": "Το τηλέφωνο είναι ήδη καταχωρημένο",
    "EmailAlreadyRegistered": "Η αλληλογραφία έχει ήδη εγγραφεί",
    "FillInTheFieldsCorrectly": "Συμπληρώστε σωστά τα πεδία",
    "ThisAccountDoesNotExist": "Αυτός ο λογαριασμός δεν υπάρχει",
    "LinkIsNotValid": "Ο σύνδεσμος δεν είναι έγκυρος",
    "AMessageHasBeenSentToTheSpecifiedLogin": "Ένα μήνυμα έχει σταλεί στο καθορισμένο όνομα χρήστη",
    "IncorrectCaptcha": "Λάθος captcha",
    "CaptchaIsNotValid": "Το Captcha δεν είναι πλέον έγκυρο",
    "EnterCaptcha": "Εισαγάγετε captcha",
    "EnterSMS": "Εισαγάγετε κωδικό από SMS",
    "SMSCodeIsNoLongerValid": "Ο κωδικός SMS δεν είναι πλέον έγκυρος",
    "SMSCodeIsIncorrect": "Ο κωδικός SMS είναι λανθασμένος",
    "MailNotVerifiedLinkTimedOut": "Η αλληλογραφία δεν επιβεβαιώθηκε, ο σύνδεσμος έληξε",
    "DemoLogin": "Επίδειξη σύνδεσης",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Είστε βέβαιοι ότι θέλετε να εισέλθετε στην <b> επίδειξη </b> για να τη δοκιμάσετε;",
    "FillInAdditionalInformation": "Συμπληρώστε επιπλέον πληροφορίες",
    "ToCompleteRegistration": "Για την ολοκλήρωση της εγγραφής",
    "LinkThirdPartyServices": "Σύνδεση υπηρεσιών τρίτων",
    "Complete": "Να ολοκληρωσω",
    "AccountAlreadyLinked": "Ο λογαριασμός είναι ήδη συνδεδεμένος",
    "AccountNotRegistered": "Ο λογαριασμός δεν είναι εγγεγραμμένος",
    "NotMyNumber": "Όχι ο αριθμός μου",
    "Back": "Πίσω",
    "numberOfAttemptsToCheckAuth": "Αριθμός προσπαθειών σύνδεσης:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Λάθος κωδικός πρόσβασης, αριθμός προσπαθειών σύνδεσης:",
    "TryAgainAfterMinute": "Δοκιμάστε ξανά σε ένα λεπτό",
    "Help": "Υποστήριξη",
    "y": "Ναί",
    "n": "Δεν",
    "doYouConfirmEmail": "Είστε βέβαιοι ότι θέλετε να αλλάξετε το email σας;",
    "success": "Επιτυχώς",
    "goToAutorize": "Μεταβείτε στην εξουσιοδότηση",
    "UserEmailAlreadyExists": "Ο χρήστης με αυτό το email υπάρχει ήδη",
    "UserPhoneAlreadyExists": "Ο χρήστης με αυτόν τον αριθμό τηλεφώνου υπάρχει ήδη",
    "TermOfUse": "Οροι χρήσης",
    "PrivacyPolicy": "Πολιτική Απορρήτου",
    "accountNotRegistered": "ο λογαριασμός δεν είναι καταχωρημένος",
    "wantToReg": "Θα θέλατε να εγγραφείτε;",
    "weSuccessChangeYouEmail": "Αλλάξαμε με επιτυχία τη διεύθυνση email σας",
    "youCanCloseThisWindow": "Μπορείτε να κλείσετε αυτό το παράθυρο",
    "countryConfirm": "Επιβεβαίωση χώρας",
    "EmailAlreadyTied": "Η αλληλογραφία είναι ήδη συνδεδεμένη με άλλο λογαριασμό",
    "areYourCountry": "αυτή είναι η χώρα σας;",
    "EmergencyEntrance": "Παλαιά εξουσιοδότηση",
    "enterCorrectNumber": "εισάγετε τον αριθμό σε διεθνή μορφή",
    "thisPhoneRegister": "Αυτός ο αριθμός τηλεφώνου είναι ήδη καταχωρημένος στο σύστημα",
    "wantUsePhoneForAuth": "Θέλετε να συνδεθείτε χρησιμοποιώντας αυτόν τον αριθμό τηλεφώνου;",
    "ok": "ΕΝΤΑΞΕΙ",
    "regAgree": "Με την εγγραφή ενός λογαριασμού, αποδέχομαι",
    "wantBind": "Θέλετε να συνδεθείτε και να συνδέσετε την υπηρεσία",
    "bind": "δένω",
    "yoursMailBinded": "Το email σας βρίσκεται σε υπάρχοντα λογαριασμό",
    "wantUsePhoneAndBind": "έχουν ήδη εγγραφεί στο σύστημα",
    "notGetDataFromApple": "Αποτυχία λήψης δεδομένων από την Apple",
    "ErrorAppleLogin": "Σφάλμα εξουσιοδότησης μέσω Apple",
    "ErrorGoogleLogin": "Σφάλμα εξουσιοδότησης μέσω Google",
    "ErrorTelegramLogin": "Σφάλμα κατά τη σύνδεση μέσω Telegram",
    "ErrorFacebookLogin": "Σφάλμα σύνδεσης μέσω Facebook",
    "EMailOrPhone": "Εισαγάγετε έναν έγκυρο αριθμό τηλεφώνου ή διεύθυνση email.",
    "withoutName": "Κανένα όνομα",
    "goToCabinet": "Προς το γραφείο",
    "Logout": "Λήξη συνεδρίας",
    "creationDate": "Ημερομηνία δημιουργίας",
    "preparingCompany1": "Δημιουργούμε την εταιρεία σας, αφήστε μας ένα λεπτό...",
    "preparingCompany2": "Ετοιμάζουμε μια διεπαφή για τη διαχείριση αγαθών και υπηρεσιών...",
    "preparingCompany3": "Εργαζόμαστε για βολική χρηματοοικονομική λογιστική...",
    "preparingCompany4": "Κάνουμε τις τελικές ρυθμίσεις, σχεδόν έτοιμες..."
}

export default function() {
    return lang;
}