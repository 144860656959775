const lang = {
    "EnterWidth": "Login with:",
    "Registration": "Register",
    "enter": "To come in",
    "EntrWidthEmailOrPhone": "Login via email or phone",
    "login": "Authorization",
    "NoAkkGoReg": "Don't have an account? Register",
    "Password": "Password",
    "Name": "Name",
    "LastName": "Surname",
    "Phone": "Telephone",
    "Email": "Mail",
    "RepeatPassword": "repeat password",
    "Сancel": "Cancel",
    "Save": "Save",
    "Language": "Language",
    "ConfirmThisMail": "Your email has been confirmed",
    "Saved": "Saved",
    "Error": "Mistake",
    "FillInAllTheFields": "Fill in all the fields",
    "Next": "Further",
    "MinPassLength": "Minimum password length 4 characters",
    "ForgotYourPassword": "Forgot your password",
    "RestorePassword": "Restore password",
    "UserMustBeRegistered": "User must be registered",
    "Confirm": "Confirm",
    "WrongFormat": "Wrong format",
    "WrongPassword": "Incorrect password",
    "WrongLoginOrPassword": "wrong login or password",
    "PhoneAlreadyRegistered": "Phone already registered",
    "EmailAlreadyRegistered": "Mail already registered",
    "FillInTheFieldsCorrectly": "Correctly fill in the fields",
    "ThisAccountDoesNotExist": "This account does not exist",
    "LinkIsNotValid": "Link is not valid",
    "AMessageHasBeenSentToTheSpecifiedLogin": "A message has been sent to the specified username",
    "IncorrectCaptcha": "Wrong captcha",
    "CaptchaIsNotValid": "Captcha is no longer valid",
    "EnterCaptcha": "Enter captcha",
    "EnterSMS": "Enter code from SMS",
    "SMSCodeIsNoLongerValid": "SMS code is no longer valid",
    "SMSCodeIsIncorrect": "SMS code is incorrect",
    "MailNotVerifiedLinkTimedOut": "Mail not confirmed, link expired",
    "DemoLogin": "Demo login",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Are you sure you want to enter the <b> demo </b> to try it out?",
    "FillInAdditionalInformation": "Fill in additional information",
    "ToCompleteRegistration": "To complete registration",
    "LinkThirdPartyServices": "Link third party services",
    "Complete": "To complete",
    "AccountAlreadyLinked": "Account already linked",
    "AccountNotRegistered": "Account not registered",
    "NotMyNumber": "Not my number",
    "Back": "Back",
    "numberOfAttemptsToCheckAuth": "Number of login attempts:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Wrong password, number of login attempts:",
    "TryAgainAfterMinute": "Please try again in a minute",
    "Help": "Support",
    "y": "Yes",
    "n": "Not",
    "doYouConfirmEmail": "Are you sure you want to change your email?",
    "success": "Successfully",
    "goToAutorize": "Go to authorization",
    "UserEmailAlreadyExists": "User with this email already exists",
    "UserPhoneAlreadyExists": "User with this phone number already exists",
    "TermOfUse": "Terms of Use",
    "PrivacyPolicy": "Privacy Policy",
    "accountNotRegistered": "account not registered",
    "wantToReg": "Would you like to register?",
    "weSuccessChangeYouEmail": "We have successfully changed your email address",
    "youCanCloseThisWindow": "You can close this window",
    "countryConfirm": "Country confirmation",
    "EmailAlreadyTied": "Mail is already linked to another account",
    "areYourCountry": "is this your country?",
    "EmergencyEntrance": "Old authorization",
    "enterCorrectNumber": "Enter number in international format",
    "thisPhoneRegister": "This phone number is already registered in the system",
    "wantUsePhoneForAuth": "Would you like to log in using this phone number?",
    "ok": "ok",
    "regAgree": "By registering an account, I accept",
    "wantBind": "Want to sign in and link the service",
    "bind": "bind",
    "yoursMailBinded": "Your email is in an existing account",
    "wantUsePhoneAndBind": "already registered in the system",
    "notGetDataFromApple": "Failed to receive data from Apple",
    "ErrorAppleLogin": "Error authorizing via Apple",
    "ErrorGoogleLogin": "Error authorizing via Google",
    "ErrorTelegramLogin": "Error while logging in via Telegram",
    "ErrorFacebookLogin": "Error logging in via Facebook",
    "EMailOrPhone": "Please enter a valid phone number or email address.",
    "withoutName": "No name",
    "goToCabinet": "To the office",
    "Logout": "End session",
    "creationDate": "Date of creation",
    "preparingCompany1": "We are creating your company, please give us a minute...",
    "preparingCompany2": "We are preparing an interface for managing goods and services...",
    "preparingCompany3": "We are working on convenient financial accounting...",
    "preparingCompany4": "We are making final settings, almost ready..."
}

export default function() {
    return lang;
}