<template>
    <div class="choiceCompanyBox" v-if="data">
        <div style="width:100%; min-width: 200px; height: 100%; max-height: 90vh;">

            <div style="font-weight: bold; color: #414141; text-align: center; font-size: 12pt;">
                <div class="usr-icon-login-container">
                    <div class="user-icon-div">
                        <img src="@/assets/userSVG.svg" class="account-icon" alt="">
                    </div>
                    <span class="person-login">{{ userLogin }}</span>
                </div>
                <hr>
            </div>
            <div style="max-height: calc(90vh - 230px); overflow-y: auto;">
                <div v-for="(item, index) in data.companies" :key="index" class="companyItem" @click="$emit('goToCompany',item)">
                    <div style="font-weight: bold;">{{ item.company_name }}</div>
                    <div class="createDateCompany">{{ lang.creationDate }}: {{ item.date_of_create }}</div>
                </div>
            </div>
            <hr />
            <div style="font-size: 10pt; float:right;">
                <button @click="$emit('logout')" style="color:red; text-transform: uppercase;" type="button" class="btn btn-sm btn-link">{{ lang.Logout }}</button>
            </div>

            <div style="font-size: 10pt; float:left;">
                <a href="//cabinet.skyservice.online/">
                    <button style="text-transform: uppercase;" type="button" class="btn btn-sm btn-link">{{ lang.goToCabinet }}</button>
                </a>
            </div>
        </div>
    </div>

</template>
<script>
export default {
props:['data'],
computed:{
    userLogin(){
        // Установка имени пользователя (если доступно, иначе используется значение по умолчанию)
        try {
            if(this.data.user.name){
                return this.data.user.name;
            }else{
                return this.data.user.phone;
            }
        } catch (error) {
            return  this.lang['withoutName'];
        }
    },
    lang() {
            return window.lang;
    }
}
}
</script>

<style scoped>

.choiceCompanyBox{
    display: flex;
    align-items: center;
    justify-content: center;
}

.companyItem{
    padding:10px; 
    margin-bottom:5px;
    border-radius: 10px;
  }

  .companyItem:hover, .companyItem:active{
    background: #f4f4f4;
    cursor: pointer;
  }

  .createDateCompany{
    color:gray;
  }

  
.usr-icon-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.account-icon {
    height: 3rem;
}

.user-icon-div {
    /* border: 1px solid gray; */
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 999px;
    margin-bottom: 1rem;
}

.person-login {
    font-size: 1.3rem;
    font-weight: 500;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
</style>